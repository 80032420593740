import './CheckForm.scss';

type CheckProps = {
    label: string,
    onCheck: Function,
};

export const CheckForm = ({ label, onCheck }: CheckProps) => {
    return(<div className='check-form'>
        <div>
            <input type='checkbox' id='input-check' onChange={e => onCheck(e.target.checked)}/>
        </div>
        <div>
            <p>{label}</p>
        </div>
    </div>);
};
