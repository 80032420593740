import React from 'react';
const logo = ({fill, stroke, className}) => {
    return (<><svg className={className} width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group">
<path id="Vector" d="M14.9791 0.0492394L14.7049 28H14.626C6.86773 27.9241 0.640128 21.607 0.714775 13.8882C0.7911 6.16856 7.14199 -0.0266889 14.9011 0.048405H14.98L14.9791 0.0492394Z" fill={fill}/>
<path id="Vector_2" d="M31.129 9.82318L17.8267 13.9767L31.0477 18.1052L30.9512 27.9525H30.8732C23.1141 27.8766 16.8857 21.5587 16.962 13.8399C17.0366 6.12189 23.3867 -0.0733547 31.1467 0.000904808H31.2247L31.1282 9.82318H31.129Z" fill={fill}/>
</g>
</svg>
</>)
}
export default logo;
    