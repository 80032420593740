import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import {Paginate} from "../paginate";
import "./DataTable.scss"
import {TableHeader} from "../table-header";
import { DatatableProps, Service } from "./model";
import { Row } from "./components";

export const DataTable = forwardRef(({
  colums,
  data,
  service,
  paginated,
  header,
  rowCheckCallback,
  AcctionButtons,
  emptyLegend,
  tableEmpty,
  headerClass,
  minWidth,
  tableId,
  collapsableRows,
  collapsableChildrenIndex,
  openRow,
  CustomHeader,
  rowClass,
  withChecks
}: DatatableProps, ref) => {
  const [tableData, setTableData] = useState<
    Array<{ [key: string]: string | boolean | number| Array<unknown> }>
  >([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [checkedRows, setCheckedRows] = useState<boolean[]>();

  const handleRowCheck = (isSelected: boolean, data: any, rowIndex: number) => {
    const updatedChecks = [...checkedRows!];
    updatedChecks[rowIndex] = isSelected;
    setCheckedRows(updatedChecks);
    rowCheckCallback?.(isSelected, data);
  };

  const uncheckAll = () => {
      setCheckedRows(data?.map(() => false));
  };

  useImperativeHandle(ref, () => ({
    uncheckAll
  }));

  useEffect(() => {
    if (data) {
      setTableData(data);

      if(withChecks){
        setCheckedRows(data.map(() => false));
      }
    }
  }, [data]);

  useEffect(() => {
    const callService = async (service: Service) => {
      try {
        const response = await service.function(service.params);
        setTableData(response[service.dataIndex]);
      } catch (error) {
        setHasError(true);
        setTableData([]);
      }
    };
    if (service) callService(service);
  }, [data]);
  if (tableData.length === 0 && !tableEmpty)
    return (
      <div className="no-data">
        <div>
          <img src={"/img/icons/document-text.svg"} alt="document" />
        </div>
        <p>{emptyLegend || "No hay datos que mostrar"}</p>
      </div>
    );
  return (<div className='w-full overflow-auto'>
    <div className='w-full overflow-auto' style={{minWidth:minWidth ? minWidth : '690px'}}>
      {header && (
        <div className="row w-full">
          <TableHeader
            title={header.title}
            subtitle={header.text}
            showSearch={header.showSearch}
            shearchCallback={header.searchCallback}
            Filters={header.Filters}
            classCustom={header.classCustom}
            placeholderSearch={header.placeholderSearch}
          />
        </div>
      )}
      {CustomHeader && <CustomHeader />}
      <div className="table-container w-full">
        <table id={tableId || `table-data-${Math.random()}`} className='w-full'>
          <thead className={headerClass}>
            <tr>
              {colums.map(({ size, name },i) => (
                <td
                  key={`table-header-${name}-${i}`}
                  className={`flex-${size || 1}`}
                >
                  {name}
                </td>
              ))}
            </tr>
          </thead>
          <tbody className='w-full'>
            {tableData.map((row, index) => {
              let childData = []
                    try {
                      // eslint-disable-next-line no-eval
                      childData = eval('row.'+collapsableChildrenIndex)
                      
                    } catch {
                      childData = []
                      
                    }
              const childTable = collapsableRows ? 
                <tr className={`${index === openRow ? 'opacity-100 visible static  w-full': 'opacity-0 invisible absolute z-[-1]'}  max-h-48 h-48 bg-neutral-2 overflow-auto`}><table >
                {
                  childData?.map?.((childRow:{ [key: string]: string | boolean | number| Array<unknown> }, i:number) => <Row colums={colums} row={childRow} rowCheckCallback={rowCheckCallback} index={i} isChildRow key={`table-body-row-child-${i}`} />)
                }
              </table></tr> : <></>

              return (
              <React.Fragment key={`table-body-row-${index + 1}`}>
                <Row 
                  isChecked={checkedRows?.[index]} 
                  rowClass={rowClass} 
                  colums={colums} 
                  row={row} 
                  rowCheckCallback={(checked, data) => handleRowCheck(checked, data, index)} 
                  index={index} 
                  isParentRow 
                  key={`table-body-row-${index + 1}`} 
                />
                {childTable}
              </React.Fragment>
            )})}
            {hasError && <span>Ocurrio un error al cargar los datos</span>}
          </tbody>
        </table>
        <div className="table-footer">
          {AcctionButtons && (
            <div className="table-buttons flex-1">
              <AcctionButtons />
            </div>
          )}
          {paginated && (
            <div className="paginate flex-1">
              <Paginate
                itemsPerPage={paginated.itemsPerPage}
                length={paginated.total}
                onPageChange={(e:number)=> paginated.onPageChange?.(e)}
                current={paginated.current}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
    
  );
});
