import React from 'react';
const documentUpload = ({fill, stroke, className}) => {
    return (<><svg className={className} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/linear/document-upload">
<g id="document-upload">
<path id="Vector" d="M5.99999 11.833V7.83301L4.66666 9.16634" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_2" d="M6 7.83301L7.33333 9.16634" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_3" d="M14.6667 7.16634V10.4997C14.6667 13.833 13.3333 15.1663 10 15.1663H6.00001C2.66668 15.1663 1.33334 13.833 1.33334 10.4997V6.49967C1.33334 3.16634 2.66668 1.83301 6.00001 1.83301H9.33334" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_4" d="M14.6667 7.16634H12C10 7.16634 9.33334 6.49967 9.33334 4.49967V1.83301L14.6667 7.16634Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
</svg>
</>)
}
export default documentUpload;
    