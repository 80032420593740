import React from 'react';
const calendarEdit = ({fill, stroke, className}) => {
    return (<><svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.3335 3.83301C5.06016 3.83301 4.8335 3.60634 4.8335 3.33301V1.33301C4.8335 1.05967 5.06016 0.833008 5.3335 0.833008C5.60683 0.833008 5.8335 1.05967 5.8335 1.33301V3.33301C5.8335 3.60634 5.60683 3.83301 5.3335 3.83301Z" fill={fill}/>
<path d="M10.6665 3.83301C10.3932 3.83301 10.1665 3.60634 10.1665 3.33301V1.33301C10.1665 1.05967 10.3932 0.833008 10.6665 0.833008C10.9398 0.833008 11.1665 1.05967 11.1665 1.33301V3.33301C11.1665 3.60634 10.9398 3.83301 10.6665 3.83301Z" fill={fill}/>
<path d="M5.66667 9.66692C5.58 9.66692 5.49333 9.64693 5.41333 9.6136C5.32666 9.58027 5.26001 9.53358 5.19334 9.47358C5.07334 9.34692 5 9.17359 5 9.00026C5 8.82692 5.07334 8.6536 5.19334 8.52693C5.26001 8.46693 5.33333 8.42025 5.41333 8.38691C5.57333 8.32025 5.76 8.32025 5.92 8.38691C6 8.42025 6.07333 8.46693 6.13999 8.52693C6.16666 8.56026 6.19999 8.59359 6.21999 8.62692C6.24666 8.66692 6.26668 8.70692 6.28001 8.74692C6.30001 8.78692 6.31334 8.82692 6.32001 8.86692C6.32667 8.91358 6.33333 8.96026 6.33333 9.00026C6.33333 9.17359 6.25999 9.34692 6.13999 9.47358C6.07333 9.53358 6 9.58027 5.92 9.6136C5.84 9.64693 5.75333 9.66692 5.66667 9.66692Z" fill={fill}/>
<path d="M8.00016 9.66679C7.9135 9.66679 7.82683 9.6468 7.74683 9.61347C7.66016 9.58013 7.5935 9.53345 7.52684 9.47345C7.40684 9.34679 7.3335 9.17346 7.3335 9.00012C7.3335 8.96012 7.34016 8.91345 7.34682 8.86678C7.35349 8.82678 7.36682 8.78679 7.38682 8.74679C7.40015 8.70679 7.42017 8.66679 7.44684 8.62679C7.47351 8.59346 7.50017 8.56013 7.52684 8.5268C7.7735 8.28013 8.22016 8.28013 8.47349 8.5268C8.50016 8.56013 8.52682 8.59346 8.55349 8.62679C8.58015 8.66679 8.60017 8.70679 8.61351 8.74679C8.63351 8.78679 8.64684 8.82678 8.6535 8.86678C8.66017 8.91345 8.66683 8.96012 8.66683 9.00012C8.66683 9.17346 8.59349 9.34679 8.47349 9.47345C8.34682 9.59345 8.18016 9.66679 8.00016 9.66679Z" fill={fill}/>
<path d="M5.66667 11.9999C5.58 11.9999 5.49333 11.9799 5.41333 11.9466C5.33333 11.9133 5.26001 11.8666 5.19334 11.8066C5.07334 11.6799 5 11.5066 5 11.3333C5 11.2933 5.00666 11.2466 5.01333 11.2066C5.01999 11.1599 5.03332 11.1199 5.05332 11.0799C5.06666 11.0399 5.08668 10.9999 5.11334 10.9599C5.13334 10.9266 5.16667 10.8933 5.19334 10.8599C5.26001 10.7999 5.33333 10.7533 5.41333 10.7199C5.57333 10.6533 5.76 10.6533 5.92 10.7199C6 10.7533 6.07333 10.7999 6.13999 10.8599C6.16666 10.8933 6.19999 10.9266 6.21999 10.9599C6.24666 10.9999 6.26668 11.0399 6.28001 11.0799C6.30001 11.1199 6.31334 11.1599 6.32001 11.2066C6.32667 11.2466 6.33333 11.2933 6.33333 11.3333C6.33333 11.5066 6.25999 11.6799 6.13999 11.8066C6.07333 11.8666 6 11.9133 5.92 11.9466C5.84 11.9799 5.75333 11.9999 5.66667 11.9999Z" fill={fill}/>
<path d="M13.6668 6.55957H2.3335C2.06016 6.55957 1.8335 6.3329 1.8335 6.05957C1.8335 5.78624 2.06016 5.55957 2.3335 5.55957H13.6668C13.9402 5.55957 14.1668 5.78624 14.1668 6.05957C14.1668 6.3329 13.9402 6.55957 13.6668 6.55957Z" fill={fill}/>
<path d="M10.5465 15.1871C10.2932 15.1871 10.0532 15.0937 9.87988 14.9204C9.67321 14.7137 9.57987 14.4137 9.62654 14.1004L9.75321 13.2004C9.78654 12.9671 9.92655 12.6871 10.0932 12.5204L12.4532 10.1604C12.7732 9.84039 13.0865 9.67372 13.4265 9.64039C13.8465 9.60039 14.2532 9.77372 14.6399 10.1604C15.0465 10.5671 15.5932 11.3937 14.6399 12.3471L12.2799 14.7071C12.1132 14.8737 11.8332 15.0137 11.5999 15.0471L10.6999 15.1737C10.6465 15.1804 10.5999 15.1871 10.5465 15.1871ZM13.5399 10.6337C13.5332 10.6337 13.5265 10.6337 13.5199 10.6337C13.4265 10.6404 13.2999 10.7271 13.1599 10.8671L10.7999 13.2271C10.7799 13.2471 10.7465 13.3137 10.7465 13.3404L10.6265 14.1737L11.4599 14.0537C11.4865 14.0471 11.5532 14.0137 11.5732 13.9937L13.9332 11.6337C14.2265 11.3404 14.2665 11.1937 13.9332 10.8604C13.8265 10.7604 13.6732 10.6337 13.5399 10.6337Z" fill={fill}/>
<path d="M13.9466 12.8336C13.8999 12.8336 13.8532 12.8269 13.8132 12.8136C12.9332 12.5669 12.2332 11.867 11.9866 10.987C11.9132 10.7203 12.0666 10.4469 12.3332 10.3669C12.5999 10.2936 12.8732 10.447 12.9532 10.7136C13.1066 11.2603 13.5399 11.6936 14.0866 11.8469C14.3532 11.9203 14.5066 12.2003 14.4332 12.467C14.3666 12.687 14.1666 12.8336 13.9466 12.8336Z" fill={fill}/>
<path d="M8 15.1663H5.33333C2.9 15.1663 1.5 13.7663 1.5 11.333V5.66634C1.5 3.23301 2.9 1.83301 5.33333 1.83301H10.6667C13.1 1.83301 14.5 3.23301 14.5 5.66634V7.99967C14.5 8.27301 14.2733 8.49967 14 8.49967C13.7267 8.49967 13.5 8.27301 13.5 7.99967V5.66634C13.5 3.75967 12.5733 2.83301 10.6667 2.83301H5.33333C3.42667 2.83301 2.5 3.75967 2.5 5.66634V11.333C2.5 13.2397 3.42667 14.1663 5.33333 14.1663H8C8.27333 14.1663 8.5 14.393 8.5 14.6663C8.5 14.9397 8.27333 15.1663 8 15.1663Z" fill={fill}/>
</svg>
</>)
}
export default calendarEdit;
    