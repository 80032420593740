import React from 'react';
const livingrock = ({fill, stroke, className}) => {
    return (<><svg className={className} width="160" height="34" viewBox="0 0 160 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_10290)">
<path d="M35.2024 10.8516H37.8654V19.8978H43.7161V22.2432H35.2024V10.8516Z" fill={fill}/>
<path d="M47.2815 10.8516H49.9445V22.2432H47.2815V10.8516Z" fill={fill}/>
<path d="M53.1519 10.8516H55.9129L59.0516 19.7862L62.2737 10.8516H64.9514L60.6308 22.2432H57.4087L53.1519 10.8516Z" fill={fill}/>
<path d="M68.1587 10.8516H70.8217V22.2432H68.1587V10.8516Z" fill={fill}/>
<path d="M75.2748 10.8516H78.1192L83.97 18.679H84.0043L83.9062 15.8336V10.8564H86.5839V22.248H83.7395L77.8887 14.4205H77.8544L77.9378 17.266V22.2432H75.2748V10.8516Z" fill={fill}/>
<path d="M90.4778 16.8404C90.4778 12.8684 92.8759 10.625 97.1475 10.625C98.4275 10.625 99.7124 10.7852 101.404 11.2417V13.6356C99.6143 13.1306 98.3638 12.9703 97.1966 12.9703C94.4992 12.9703 93.2536 14.2085 93.2536 16.7287C93.2536 18.9915 94.205 20.1277 95.9803 20.1277C97.8537 20.1277 98.9866 18.7924 99.119 16.4033H101.699V22.2448L99.5947 22.2594L99.3985 18.4039H99.3642C99.0994 20.992 97.5889 22.473 95.176 22.473C92.2678 22.473 90.4778 20.3268 90.4778 16.8452V16.8404Z" fill={fill}/>
<path d="M105.862 10.8516H110.874C114.327 10.8516 115.788 12.0704 115.788 14.2846C115.788 16.1735 114.111 17.2126 112.454 17.3437V17.3777C114.033 17.5234 114.705 18.174 115.151 19.2812L116.416 22.2432H113.459L112.537 19.8201C112.125 18.747 111.433 18.242 110.168 18.242H108.525V22.2432H105.862V10.8516ZM113.091 14.6925C113.091 13.4737 112.385 12.9687 110.462 12.9687H108.525V16.4988H110.35C112.272 16.4988 113.096 15.9792 113.096 14.6925H113.091Z" fill={fill}/>
<path d="M119.241 16.549C119.241 12.9849 121.904 10.625 125.484 10.625C129.064 10.625 131.727 12.9849 131.727 16.549C131.727 20.1131 129.064 22.473 125.484 22.473C121.904 22.473 119.241 20.1131 119.241 16.549ZM128.951 16.549C128.951 14.2571 127.539 12.9703 125.484 12.9703C123.429 12.9703 122.017 14.2571 122.017 16.549C122.017 18.8409 123.429 20.1131 125.484 20.1131C127.539 20.1131 128.951 18.8458 128.951 16.549Z" fill={fill}/>
<path d="M135.072 16.549C135.072 12.9849 137.715 10.625 141.413 10.625C142.448 10.625 143.698 10.7852 144.993 11.13V13.5724C143.792 13.184 142.477 12.9703 141.491 12.9703C139.275 12.9703 137.843 14.2231 137.843 16.549C137.843 18.8749 139.275 20.1277 141.491 20.1277C142.512 20.1277 143.841 19.9335 145.106 19.5402V21.9826C143.904 22.3079 142.512 22.4682 141.423 22.4682C137.71 22.4682 135.062 20.1083 135.062 16.5442L135.072 16.549Z" fill={fill}/>
<path d="M153.085 17.6885H151.574V22.2432H148.911V10.8516H151.574V15.6685H153.085C155.419 15.6685 156.238 13.6339 156.42 10.8516H159.132C158.838 14.0078 157.916 15.9938 155.056 16.4648V16.4988L155.993 16.6105C157.538 16.8241 158.342 17.4894 158.852 18.9558L160 22.2432H157.043L156.238 19.6405C155.792 18.242 154.841 17.6885 153.085 17.6885Z" fill={fill}/>
<path d="M14.6244 22.9242L14.6538 34.0001L29.3076 23.7108V12.6445L14.6244 22.9242Z" fill={fill}/>
<path d="M14.6832 22.9242L14.6538 34.0001L0 23.7108V12.6445L14.6832 22.9242Z" fill={fill}/>
<path d="M14.6244 11.076L14.6538 0L29.3076 10.2893V21.3556L14.6244 11.076Z" fill={fill}/>
<path d="M14.6832 11.076L14.6538 0L0 10.2893V21.3556L14.6832 11.076Z" fill={fill}/>
</g>
<defs>
<clipPath id="clip0_1_10290">
<rect width="160" height="34" fill="white"/>
</clipPath>
</defs>
</svg>
</>)
}
export default livingrock;
    