import { LoginRequest, LoginResponse } from "../../interfaces/authInterface";
import { IUserProfile } from "../../interfaces/userProfileInterface";
import { URL_API } from "../../utils/constants";
import { ConsumeService } from "../ConsumeService";

export const AuthService = {

    async authUser (bearer: string): Promise<IUserProfile | null> {
        try {
            const { data } = await ConsumeService.get(URL_API.AUTH_AUTHUSER, { headers: { 'Authorization': `Bearer ${bearer}` } });
            return data.data ?? null;
    
        } catch (error) {
            return null
        }
    },

    async login ({ username, password, forced = false, pbk }: LoginRequest): Promise<LoginResponse> {

        try {
            const { data } = await ConsumeService.post(URL_API.AUTH_LOGIN, { username, password, forced, pbk });
            return {
                status: 'ok',
                code: 200,
                data: data.data,
            };
    
        } catch (error: any) {
            if (error.response?.data) {
                return {
                    status: error.response.status,
                    code: error.response.code || error.response.status,
                    data: error.response.data,
                }
            }
            return {
                status: error.message,
                code: 500,
                data: {token:''}
            };
        }
    },

    async logout () {
    
        try {
            await ConsumeService.post(URL_API.AUTH_LOGOUT, {});
        } catch (error: any) {
            console.log(error);
        }
    }
}