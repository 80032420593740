import React from 'react';
const help = ({fill, stroke, className}) => {
    return (<><svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/linear/message-question">
<g id="message-question">
<path id="Vector" d="M17.5 18.4301H13.5L9.04999 21.39C8.38999 21.83 7.5 21.3601 7.5 20.5601V18.4301C4.5 18.4301 2.5 16.4301 2.5 13.4301V7.42999C2.5 4.42999 4.5 2.42999 7.5 2.42999H17.5C20.5 2.42999 22.5 4.42999 22.5 7.42999V13.4301C22.5 16.4301 20.5 18.4301 17.5 18.4301Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_2" d="M12.5001 11.36V11.15C12.5001 10.47 12.9201 10.11 13.3401 9.82001C13.7501 9.54001 14.16 9.18002 14.16 8.52002C14.16 7.60002 13.4201 6.85999 12.5001 6.85999C11.5801 6.85999 10.8401 7.60002 10.8401 8.52002" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_3" d="M12.4955 13.75H12.5045" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
</svg>
</>)
}
export default help;
    