import Icon from '../../assets/icon';
import './OptionCard.scss';

type OptionCardProps = {
    label: string,
    icon: string,
}

export const OptionCard = ({ label, icon }: OptionCardProps) => {
    return(<div className='option-card-container' onClick={() => console.log(label)}>
        <div className='icon bg-button bg-marketPlaceBg'>
            <Icon iconName={icon} className='stroke-marketPlaceContrast stroke-2' alt={'icon'} />
        </div>
        <p className='text-black'>{label}</p>
    </div>);
};
