import React, { useState, forwardRef, useImperativeHandle } from "react";
import {SearchInput} from "../search-input";
import {Modal} from "../../components";
import "./TableHeader.scss";

interface TableHeaderProps {
  title?: string;
  Filters?: React.ReactNode;
  showSearch?: boolean;
  shearchCallback?: (e: string) => void;
  subtitle?: string;
  classCustom?: string;
  placeholderSearch?: string
}

/**
 * Encabezado para las tablas, contiene el diseño para buscar elementos y
 * realizar filtros sobre los mismos
 * @param {string} title Titulo de la tabla
 * @param {Node} Filters Componente con los filtros, el resultado se propaga
 *              por medio de un callback
 */
export const TableHeader = forwardRef(({
  title = "",
  Filters,
  subtitle,
  showSearch,
  placeholderSearch,
  shearchCallback,
  classCustom = 'bg-neutral-1 h-[94px]'
}: TableHeaderProps, ref) => {
  const [openFilters, setOpenFilters] = useState<boolean>(false);

  const closeFilters = () => {
    setOpenFilters(false);
  }

  useImperativeHandle(ref, () => ({
    closeFilters
  }));

  return (
    <div className={`table-header-container ${classCustom}`}>
      <div>
        <p className="title">{title}</p>
        <p className="subtitle">{subtitle}</p>
      </div>

      <div className="filters-container">
        {showSearch && (
          <div className="search">
            <SearchInput
              type="form"
              placeholder={placeholderSearch ?? 'Buscar'}
              callback={(e: string) => shearchCallback?.(e)}
            />
          </div>
        )}
        {Filters && (
          <div className="filter" onClick={() => setOpenFilters(true)}>
            <p>Filtrar</p>
            <img src={"/img/icons/filters.svg"} alt="filters" />
          </div>
        )}
      </div>
      <Modal
        open={openFilters}
        title="Filtros"
        onClose={closeFilters}
        children={Filters}
      />
    </div>
  );
});
