import React from 'react';
const livingrockAppIcon = ({fill, stroke, className}) => {
    return (<><svg className={className} width="30" height="34" viewBox="0 0 30 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.6245 22.9242L14.6539 34.0001L29.3077 23.7108V12.6445L14.6245 22.9242Z" fill={fill}/>
<path d="M14.6832 22.9242L14.6538 34.0001L0 23.7108V12.6445L14.6832 22.9242Z" fill={fill}/>
<path d="M14.6245 11.076L14.6539 0L29.3077 10.2893V21.3556L14.6245 11.076Z" fill={fill}/>
<path d="M14.6832 11.076L14.6538 0L0 10.2893V21.3556L14.6832 11.076Z" fill={fill}/>
</svg>
</>)
}
export default livingrockAppIcon;
    