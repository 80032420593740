import React from 'react';
const view = ({fill, stroke, className}) => {
    return (<><svg className={className} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/linear/eye">
<g id="eye">
<path id="Vector" d="M10.3866 8.49995C10.3866 9.81995 9.31995 10.8866 7.99995 10.8866C6.67995 10.8866 5.61328 9.81995 5.61328 8.49995C5.61328 7.17995 6.67995 6.11328 7.99995 6.11328C9.31995 6.11328 10.3866 7.17995 10.3866 8.49995Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_2" d="M8.00002 14.0138C10.3534 14.0138 12.5467 12.6271 14.0734 10.2271C14.6734 9.28714 14.6734 7.70714 14.0734 6.76714C12.5467 4.36714 10.3534 2.98047 8.00002 2.98047C5.64668 2.98047 3.45335 4.36714 1.92668 6.76714C1.32668 7.70714 1.32668 9.28714 1.92668 10.2271C3.45335 12.6271 5.64668 14.0138 8.00002 14.0138Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
</svg>
</>)
}
export default view;
    