import { useEffect, useState } from "react";
import ModalView from "react-modal";
import "./Modal.scss";

type ModalProps = {
  open: boolean;
  title: string;
  onClose: Function;
  children: React.ReactNode;
};

const customStyles = {
  overlay: {
    backgroundColor: "#000F0880",
    display: "flex",
    alignContent: "center",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    margin: "auto",
    padding: "24px",
    transform: "translate(-50%, -50%)",
    borderRadius: "16px",
    overflow: "hidden"
  },
};

export const Modal = ({ open, title, onClose, children }: ModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeModal = () => {
    onClose();
    setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(open);
    document.getElementsByTagName("body")[0].style.overflow = open
      ? "hidden"
      : "auto";
  }, [open]);

  return (
    <ModalView
      isOpen={isOpen}
      style={customStyles}
      contentLabel={title}
      ariaHideApp={false}
    >
      <div className="modal-holder">
        <div className="modal-container">
          <h3>{title}</h3>
          <div onClick={closeModal} className="close">
            <img src={`/img/icons/close.svg`} alt="close" />
          </div>
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </ModalView>
  );
};

