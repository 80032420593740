import React from 'react';
const kubitq = ({fill, stroke, className}) => {
    return (<><svg className={className} width="81" height="14" viewBox="0 0 81 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group">
<path id="Vector" d="M5.01713 6.99875L2.80456 6.97706L2.75339 12.161L0.224609 12.1368L0.341192 0.283691L2.86998 0.307888L2.80791 6.59575L5.54554 5.35837C8.41736 3.98749 9.21751 3.80893 9.52701 0.372135L12.1153 0.397167C11.6641 4.08845 10.6652 5.22487 7.36056 5.76304V5.80226L8.18922 5.90822C10.063 6.22111 11.0434 6.99708 11.6976 8.83188L13.126 12.2612L10.2416 12.2328L9.26029 9.57447C8.68576 7.72132 7.38823 7.02045 5.01797 6.99792L5.01713 6.99875Z" fill={fill}/>
<path id="Vector_2" d="M15.1347 6.90034L15.1985 0.427246L17.7272 0.451443L17.6635 6.92453C17.6392 9.40096 19.0332 10.333 21.5024 10.3572C23.9917 10.3814 25.4033 9.47606 25.4277 6.99963L25.4914 0.526537L28.0202 0.550734L27.9564 7.02383C27.9187 10.8761 25.4906 12.6559 21.4806 12.6166C17.4899 12.5783 15.097 10.751 15.1347 6.8995V6.90034Z" fill={fill}/>
<path id="Vector_3" d="M31.6368 0.466431L37.6824 0.524837C41.0608 0.557378 42.7744 1.11224 42.7517 3.37256C42.7333 5.25909 41.5037 5.77974 39.328 5.93577V5.97498C41.7369 6.13602 43.1074 7.38758 43.0872 9.45099C43.0637 11.849 40.5199 12.5265 37.6237 12.499L31.5194 12.4397L31.6368 0.466431ZM40.16 3.76055C40.1718 2.52233 39.302 2.56572 37.3067 2.5457L34.1455 2.51482L34.1052 6.65416L37.4333 5.78391C39.2525 5.30498 40.1475 5.07719 40.16 3.76055ZM37.1901 10.4706C39.5016 10.4931 40.4511 10.4281 40.4645 8.99376C40.4779 7.59868 39.5746 7.05884 37.2236 7.03631L34.1019 7.00627L34.0683 10.4406L37.1901 10.4706Z" fill={fill}/>
<path id="Vector_4" d="M46.0588 0.726685L48.5876 0.750882L48.471 12.604L45.9423 12.5798L46.0588 0.726685Z" fill={fill}/>
<path id="Vector_5" d="M55.815 3.17233L51.1726 3.12727L51.1944 0.866943L63.0281 0.982087L63.0063 3.23824L58.3438 3.19652L58.2507 12.6992L55.7219 12.675L55.815 3.17233Z" fill={fill}/>
<path id="Vector_6" d="M76.4521 10.7217L75.4154 9.91321L76.4596 10.7334L76.4521 10.7209V10.7217Z" fill={fill}/>
<path id="Vector_7" d="M77.1004 11.2298C78.2403 10.1576 78.9196 8.6758 78.9373 6.91109C78.975 3.09215 75.8583 0.534787 71.6487 0.493903C67.4391 0.453018 64.2728 2.94947 64.2359 6.76841C64.1982 10.6074 67.3149 13.1489 71.5245 13.1898C72.859 13.2031 74.0877 12.962 75.1445 12.5064L75.9312 13.6737L80.2859 13.7163L77.1004 11.2298ZM70.9022 11.1514C68.6091 10.947 66.8243 9.03374 66.8662 6.74338C66.9157 3.98744 68.8406 2.47221 71.6294 2.49891C74.4358 2.52644 76.3355 4.09674 76.3087 6.88523C76.2994 7.86812 76.0529 8.69582 75.615 9.35331L76.4529 10.7217L76.4605 10.7342L75.4163 9.91401L75.272 9.79636L72.5637 7.68289C72.3062 7.4818 71.9649 7.7972 72.1469 8.06754L73.9476 10.7342C73.0997 11.1113 72.0638 11.2557 70.9013 11.1522L70.9022 11.1514Z" fill={fill}/>
<path id="Vector_8" d="M76.4605 10.7342L75.4154 9.91321L76.4521 10.7217L76.4605 10.7342Z" fill={fill}/>
<path id="Vector_9" d="M76.4605 10.7342L75.4154 9.91321L76.4521 10.7217L76.4605 10.7342Z" fill={fill}/>
</g>
</svg>
</>)
}
export default kubitq;
    