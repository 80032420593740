import {OptionCard} from '../option-card';
import { MARKETPLACE_OPTIONS } from '../../utils/constants'; 

/**
 * Muestra las opciones de servicios para la cuenta
 * @returns 
 */
export const MarketPlace = () => {
    return (<div className='marketplace-container flex w-full flex-col justify-center lg:flex-row lg:justify-evenly gap-9 bg-neutral-1 border-t p-6'>
        {MARKETPLACE_OPTIONS.map((market, index) => <OptionCard label={market.label} icon={market.icon} key={`${market.label}-${index}`}/>)}
    </div>);
};