import React from 'react';
const error = ({fill, stroke, className}) => {
    return (<><svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group 4">
<path id="Vector 3" d="M1.55322 14.3057L7.94667 7.80566L14.3401 1.30566" stroke={stroke} strokeWidth="2" strokeLinecap="round"/>
<path id="Vector 4" d="M14.5 14.1992L8 7.80578L1.5 1.41233" stroke={stroke} strokeWidth="2" strokeLinecap="round"/>
</g>
</svg>
</>)
}
export default error;
    