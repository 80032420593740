import React from 'react';
const copy = ({fill, stroke, className}) => {
    return (<><svg className={className} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/linear/document-copy">
<path id="Vector" d="M11.333 9.43464V11.4346C11.333 14.1013 10.2663 15.168 7.59967 15.168H5.06634C2.39967 15.168 1.33301 14.1013 1.33301 11.4346V8.9013C1.33301 6.23464 2.39967 5.16797 5.06634 5.16797H7.06634" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_2" d="M11.3331 9.43464H9.19974C7.59974 9.43464 7.06641 8.9013 7.06641 7.3013V5.16797L11.3331 9.43464Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_3" d="M7.7334 1.83203H10.4001" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_4" d="M4.66699 3.83203C4.66699 2.72536 5.56033 1.83203 6.66699 1.83203H8.41366" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_5" d="M14.6663 5.83203V9.9587C14.6663 10.992 13.8263 11.832 12.793 11.832" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_6" d="M14.667 5.83203H12.667C11.167 5.83203 10.667 5.33203 10.667 3.83203V1.83203L14.667 5.83203Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</svg>
</>)
}
export default copy;
    