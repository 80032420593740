import { IconButton } from '@mui/material';
import React, { useState } from 'react'
import { Tooltip } from 'react-tooltip'

interface ICopyPaste {
    valueCopy: any
}

export const CopyPaste = ({ valueCopy }: ICopyPaste) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [currentAnchorId, setCurrentAnchorId] = useState<string | null>(null);

    const copyData = (value: string, anchorId: string) => {
        setCurrentAnchorId(anchorId);
        setTooltipVisible(true);
        navigator.clipboard.writeText(value);
        setTimeout(() => {
            setTooltipVisible(false);
        }, 1000);
    };

    return (
        <>
            <Tooltip
                id='tooltip-account'
                className='tooltip'
                isOpen={tooltipVisible}
                anchorSelect={`#${currentAnchorId}`}
            >
                ¡Copiado!
            </Tooltip>
            <IconButton
                id={`anchor-button-${valueCopy}`}
                className='h-[32px]'
                onClick={() => copyData(valueCopy, `anchor-button-${valueCopy}`)}
            >
                <img src="/img/icons/copy.svg" alt="custom icon" style={{ width: 16, height: 16 }} />
            </IconButton>
        </>
    );
};

