import React from 'react';
const arrowDown = ({fill, stroke, className}) => {
    return (<><svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/outline/arrow-down">
<g id="arrow-down">
<path id="Vector" d="M12 16.8006C11.3 16.8006 10.6 16.5306 10.07 16.0006L3.55002 9.48062C3.26002 9.19062 3.26002 8.71062 3.55002 8.42063C3.84002 8.13063 4.32002 8.13063 4.61002 8.42063L11.13 14.9406C11.61 15.4206 12.39 15.4206 12.87 14.9406L19.39 8.42063C19.68 8.13063 20.16 8.13063 20.45 8.42063C20.74 8.71062 20.74 9.19062 20.45 9.48062L13.93 16.0006C13.4 16.5306 12.7 16.8006 12 16.8006Z" fill={fill}/>
</g>
</g>
</svg>
</>)
}
export default arrowDown;
    