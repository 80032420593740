interface IBadgeProps {
    type: string
    value?: string
}

export const Badge = ({type, value = '-'}: IBadgeProps) => {
  return (
    <div className={`m-auto p-2 rounded-full border bg-movementStatus-${type} bg-opacity-10 border-opacity-20 border-movementStatus-${type} text-movementStatus-${type}`}>
        {value}
    </div>
  )
}
