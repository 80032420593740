import React from 'react';
const search = ({fill, stroke, className}) => {
    return (<><svg className={className} width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Icons/Search">
<path id="Vector" d="M9.83349 17C14.2518 17 17.8335 13.4183 17.8335 8.99999C17.8335 4.58172 14.2518 1 9.83349 1C5.41521 1 1.8335 4.58172 1.8335 8.99999C1.8335 13.4183 5.41521 17 9.83349 17Z" stroke={stroke} strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
<path id="Vector_2" d="M19.8335 19L15.4835 14.65" stroke={stroke} strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
</g>
</svg>
</>)
}
export default search;
    