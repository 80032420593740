import { URL_API } from "../../utils/constants";
import { ConsumeService } from "../ConsumeService";
import { IAccountInfo, ICard, IAccountsInfo, AllAccountsResponse } from '../../interfaces/accountsInterface';

export const AccountsService = {

  async getAffiliations (customerId: string): Promise< Array<Omit<IAccountInfo, 'nestedAccounts'> & {card:ICard}>> {
    try {
      const { data } = await ConsumeService.get(`${URL_API.AFFILIATIONS}/${customerId}`,{timeout: 60 * 10000});
      return data ?? [];

    } catch (error) {
      return []
    }
  },

  async getAccount(accountId: string): Promise<Partial<IAccountsInfo>>{
    try {
      const {data} = await ConsumeService.get(`${URL_API.ACCOUNTS}/${accountId}`);
    return data.data || {}
    } catch (err) {
      return {}
    }
  },

  async getAllAccounts (): Promise<AllAccountsResponse> {
    try {
        const { data } = await ConsumeService.get(URL_API.ALL_ACCOUNTS);
        return {
            accountsBalance: data.accountsBalance || 0,
            nestedAccountsBalance: data.nestedAccountsBalance || 0,
            currency: data.currency || 'MXN',
            totalAccounts: data.totalAccounts || 0,
            totalNestedAccounts: data.totalNestedAccounts || 0,
            accounts: data.accounts || [],
        }

    } catch (error: any) {
        return {
            accountsBalance: 0,
            nestedAccountsBalance: 0,
            currency: "MXN",
            totalAccounts: 0,
            totalNestedAccounts: 0,
            accounts: [],
        }
    }
  },
}