import React from 'react';
const exportIcon = ({fill, stroke, className}) => {
    return (<><svg className={className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/outline/export">
<g id="export">
<path id="Vector" d="M14.0834 12.7293C13.8775 12.7293 13.6717 12.6534 13.5092 12.4909C13.195 12.1768 13.195 11.6568 13.5092 11.3426L22.3925 2.45926C22.7067 2.14509 23.2267 2.14509 23.5409 2.45926C23.855 2.77342 23.855 3.29342 23.5409 3.60759L14.6575 12.4909C14.495 12.6534 14.2892 12.7293 14.0834 12.7293Z" fill={fill}/>
<path id="Vector_2" d="M23.8334 8.17949C23.3892 8.17949 23.0209 7.81116 23.0209 7.36699V2.97949H18.6334C18.1892 2.97949 17.8209 2.61116 17.8209 2.16699C17.8209 1.72283 18.1892 1.35449 18.6334 1.35449H23.8334C24.2775 1.35449 24.6459 1.72283 24.6459 2.16699V7.36699C24.6459 7.81116 24.2775 8.17949 23.8334 8.17949Z" fill={fill}/>
<path id="Vector_3" d="M16.25 24.6462H9.75002C3.86752 24.6462 1.35419 22.1328 1.35419 16.2503V9.75033C1.35419 3.86783 3.86752 1.35449 9.75002 1.35449H11.9167C12.3609 1.35449 12.7292 1.72283 12.7292 2.16699C12.7292 2.61116 12.3609 2.97949 11.9167 2.97949H9.75002C4.75585 2.97949 2.97919 4.75616 2.97919 9.75033V16.2503C2.97919 21.2445 4.75585 23.0212 9.75002 23.0212H16.25C21.2442 23.0212 23.0209 21.2445 23.0209 16.2503V14.0837C23.0209 13.6395 23.3892 13.2712 23.8334 13.2712C24.2775 13.2712 24.6459 13.6395 24.6459 14.0837V16.2503C24.6459 22.1328 22.1325 24.6462 16.25 24.6462Z" fill={fill}/>
</g>
</g>
</svg>
</>)
}
export default exportIcon;
    