import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { IFavorites } from '../../interfaces/customerInterface';
import { IBank } from '../../interfaces/transfersInterface';

interface TransferState {
    fav?: IFavorites,
    banks: IBank[]
}

const initialState: TransferState = {
    banks: []
};

export const transferSlice = createSlice({
    name: 'transfer',
    initialState,
    reducers: {
        setFav: (state, action: PayloadAction<IFavorites>) => {
            let newFav = {} as IFavorites;
            newFav.customer_id = action.payload.customer_id
            newFav.account_id = action.payload.account_id 
            newFav.account_type = action.payload.account_type 
            newFav.account_alias = action.payload.account_alias 
            newFav.beneficiary_name = action.payload.beneficiary_name 
            newFav.beneficiary_email = action.payload.beneficiary_email 
            state.fav = newFav;
        },
        unsetFav: (state) => {
            state.fav = undefined;
        },
        setBanks: (state, action: PayloadAction<IBank[]>) => {
            state.banks = action.payload
        },
    },
});

export const { setFav, unsetFav, setBanks } = transferSlice.actions;
export const transfer = (state: RootState) => state.transfer;
export default transferSlice.reducer;
