import React from 'react';
const money = ({fill, stroke, className}) => {
    return (<><svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.334 13.6663H4.66732C2.66732 13.6663 1.33398 12.6663 1.33398 10.333V5.66634C1.33398 3.33301 2.66732 2.33301 4.66732 2.33301H11.334C13.334 2.33301 14.6673 3.33301 14.6673 5.66634V10.333C14.6673 12.6663 13.334 13.6663 11.334 13.6663Z" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3.66602 6.33301V9.66634" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.334 6.33301V9.66634" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</>)
}
export default money;
    