import React from 'react';
const walletactive = ({fill, stroke, className}) => {
    return (<><svg className={className} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/linear/wallet-add">
<g id="wallet-add">
<path id="Vector" d="M14.2617 15.5752H9.26172" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_2" d="M11.7617 13.1348V18.1348" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_3" d="M12.66 2.65485L12.63 2.72485L9.72996 9.45485H6.87996C6.19996 9.45485 5.54996 9.59485 4.95996 9.84485L6.70996 5.66485L6.74996 5.56485L6.81996 5.40485C6.83996 5.34485 6.85996 5.28485 6.88996 5.23485C8.19996 2.20485 9.67996 1.51485 12.66 2.65485Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_4" d="M18.0505 9.65479C17.6005 9.51479 17.1205 9.45478 16.6405 9.45478H9.73047L12.6305 2.72479L12.6605 2.65479C12.8105 2.70479 12.9505 2.77479 13.1005 2.83479L15.3105 3.76479C16.5405 4.27479 17.4005 4.80479 17.9205 5.44479C18.0205 5.56479 18.1005 5.67479 18.1705 5.80479C18.2605 5.94479 18.3305 6.08479 18.3705 6.23479C18.4105 6.32479 18.4405 6.41479 18.4605 6.49479C18.7305 7.33479 18.5705 8.36479 18.0505 9.65479Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_5" d="M21.5217 14.3351V16.2851C21.5217 16.4851 21.5117 16.6851 21.5017 16.8851C21.3117 20.3751 19.3617 22.1351 15.6617 22.1351H7.86172C7.62172 22.1351 7.38172 22.1151 7.15172 22.0851C3.97172 21.8751 2.27172 20.1751 2.06172 16.9951C2.03172 16.7651 2.01172 16.5251 2.01172 16.2851V14.3351C2.01172 12.3251 3.23172 10.5951 4.97172 9.84508C5.57172 9.59508 6.21172 9.45508 6.89172 9.45508H16.6517C17.1417 9.45508 17.6217 9.52508 18.0617 9.65508C20.0517 10.2651 21.5217 12.1251 21.5217 14.3351Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_6" d="M6.71 5.66479L4.96 9.84479C3.22 10.5948 2 12.3248 2 14.3348V11.4048C2 8.5648 4.02 6.19479 6.71 5.66479Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_7" d="M21.5186 11.4044V14.3344C21.5186 12.1344 20.0586 10.2644 18.0586 9.66437C18.5786 8.36437 18.7286 7.34438 18.4786 6.49438C18.4586 6.40438 18.4286 6.31437 18.3886 6.23438C20.2486 7.19437 21.5186 9.16438 21.5186 11.4044Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
</svg>
</>)
}
export default walletactive;
    