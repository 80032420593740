import React from 'react';
const masefe = ({fill, stroke, className}) => {
    return (<><svg className={className} width="138" height="26" viewBox="0 0 138 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Clip path group">
<mask id="mask0_600_55099" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="138" height="26">
<g id="clippath">
<path id="Vector" d="M137.085 0H0.915039V26H137.085V0Z" fill="white"/>
</g>
</mask>
<g mask="url(#mask0_600_55099)">
<g id="Group">
<g id="Group_2">
<path id="Vector_2" d="M52.6816 12.284V21.2205H48.9416V13.4984C48.9416 11.4744 47.9188 10.3534 46.2245 10.3534C44.5302 10.3534 43.2731 11.4121 43.2731 13.5295V21.2205H39.5175V13.4984C39.5175 11.4744 38.5103 10.3534 36.8004 10.3534C35.1919 10.3534 33.849 11.4121 33.849 13.5295V21.2205H30.0934V7.18518H33.7475V8.83548C34.7391 7.34087 36.2694 6.81152 37.8466 6.81152C39.861 6.81152 41.5163 7.7223 42.4533 9.21691C43.5308 7.43428 45.3578 6.81152 47.1848 6.81152C50.4642 6.81152 52.6816 9.03009 52.6816 12.284Z" fill={fill}/>
<path id="Vector_3" d="M59.4819 14.1912C59.4819 16.3397 60.8795 18.06 63.0813 18.06C65.2831 18.06 66.6807 16.4097 66.6807 14.1912C66.6807 11.9726 65.2285 10.3223 63.0813 10.3223C60.9341 10.3223 59.4819 12.0427 59.4819 14.1912ZM70.2255 21.2205H66.5558V19.6714C65.6501 20.8001 64.2993 21.5864 62.3864 21.5864C58.5449 21.5864 55.6404 18.4103 55.6404 14.1989C55.6404 9.98757 58.5449 6.81152 62.3864 6.81152C64.2993 6.81152 65.6345 7.59775 66.5558 8.74206V7.19296H70.2255V21.2283V21.2205Z" fill={fill}/>
<path id="Vector_4" d="M84.1706 11.093C83.0619 10.5403 81.2895 9.96427 79.728 9.9487C78.3069 9.9487 77.5886 10.4547 77.5886 11.2254C77.5886 12.0349 78.5958 12.2373 79.8607 12.4319L81.1021 12.6188C84.1238 13.0858 85.8025 14.4403 85.8025 16.8224C85.8025 19.7182 83.4445 21.5864 79.4 21.5864C77.5027 21.5864 75.012 21.2206 73.2006 19.9361L74.6841 17.0637C75.8787 17.8577 77.3309 18.4415 79.4312 18.4415C81.1724 18.4415 82.0079 17.9511 82.0079 17.126C82.0079 16.4487 81.3051 16.0673 79.6889 15.8337L78.5568 15.678C75.3321 15.2265 73.7393 13.8098 73.7393 11.42C73.7393 8.53972 75.9567 6.82715 79.6186 6.82715C81.8517 6.82715 83.585 7.24751 85.4433 8.15829L84.1784 11.1008H84.1628L84.1706 11.093Z" fill={fill}/>
<path id="Vector_5" d="M93.5636 18.7526C93.5636 20.2706 92.2675 21.5784 90.7918 21.5784C89.3161 21.5784 87.9888 20.2784 87.9888 18.7526C87.9888 17.2269 89.3005 15.958 90.7918 15.958C92.2831 15.958 93.5636 17.2502 93.5636 18.7526Z" fill={fill}/>
<path id="Vector_6" d="M99.5835 12.8756H105.752C105.408 11.0774 104.331 10.0654 102.714 10.0654C100.942 10.0654 99.9114 11.1396 99.5757 12.8756H99.5835ZM109.515 14.1756C109.515 14.6115 109.484 15.1331 109.445 15.4522H99.5601C99.9661 17.4762 101.372 18.3636 103.144 18.3636C104.354 18.3636 105.666 17.8732 106.665 17.0169L108.867 19.4534C107.282 20.9169 105.268 21.5863 102.925 21.5863C98.6544 21.5863 95.6874 18.6594 95.6874 14.2378C95.6874 9.81629 98.5607 6.80371 102.738 6.80371C106.915 6.80371 109.5 9.80072 109.515 14.1756Z" fill={fill}/>
<path id="Vector_7" d="M121.75 10.5401H117.417V21.2126H113.661V10.5401H111.061V7.18506H113.661V5.44913C113.661 2.1641 115.402 0.0078125 118.65 0.0078125C120.548 0.0078125 121.875 0.560507 122.937 1.26889L121.438 4.21141C120.751 3.83775 119.923 3.53416 119.048 3.53416C117.971 3.53416 117.409 4.19584 117.409 5.50362V7.20063H121.742V10.5557L121.75 10.5401Z" fill={fill}/>
<path id="Vector_8" d="M127.145 12.8756H133.313C132.97 11.0774 131.892 10.0654 130.276 10.0654C128.504 10.0654 127.473 11.1396 127.137 12.8756H127.145ZM137.085 14.1756C137.085 14.6115 137.053 15.1331 137.014 15.4522H127.13C127.536 17.4762 128.941 18.3636 130.713 18.3636C131.924 18.3636 133.235 17.8732 134.235 17.0169L136.437 19.4534C134.852 20.9169 132.837 21.5863 130.495 21.5863C126.224 21.5863 123.257 18.6594 123.257 14.2378C123.257 9.81629 126.13 6.80371 130.307 6.80371C134.485 6.80371 137.069 9.80072 137.085 14.1756Z" fill={fill}/>
<path id="Vector_9" d="M13.6575 12.1283V15.8882H25.0726V20.7924H13.6575V26.0002H8.76978V7.23193H26.845V12.1361H13.6575V12.1283Z" fill={fill}/>
<path id="Vector_10" d="M20.9503 7.23181V12.136H13.6812V19.3677H8.79344V12.136H0.915283V7.23181H8.79344V0.155762H13.6812V7.23181H20.9503Z" fill="url(#paint0_linear_600_55099)"/>
</g>
</g>
</g>
</g>
<defs>
<linearGradient id="paint0_linear_600_55099" x1="5.39701" y1="4.32043" x2="19.8309" y2="18.2512" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="0.85" stop-color="#474747"/>
</linearGradient>
</defs>
</svg>
</>)
}
export default masefe;
    