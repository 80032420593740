import { IBank, ISpei } from "../../interfaces/transfersInterface";
import { GeneralResponse } from "../../interfaces/utilsInterface";
import { URL_API } from "../../utils/constants";
import { ConsumeService } from "../ConsumeService";

export const SpeiService = {

    /**
     * Consulta del catalogo de bancos
     */
    async getBanks (): Promise<IBank[]> {
        try {
            const { data } = await ConsumeService.get(URL_API.SPEI_BANKS);
            return data.data ?? [];
    
        } catch (error) {
            return []
        }
    },

    /**
     * Permite enviar un pago (spei out) a otro banco en la plataforma Kubit.
     * Se requiere un apikey, un token y un OTP válidos para realizar la petición.
     */
    async sendSpei (dataSpei: ISpei, otp: string): Promise<GeneralResponse> {
        try {
            const { data } = await ConsumeService.post(URL_API.SPEI_SEND, dataSpei, { headers: { 'Kubit-OTP': `OTP ${otp}` } } );
            return {
                status: data.status,
                code: data.code
            };
    
        } catch (error: any) {
            return {
                status: error.message,
                code: 500,
                message: error?.message?.message || error?.data?.message || '',
            };
        }
    },

    /**
     * Permite enviar un pago (spei out) a otro banco en la plataforma Kubit.
     * Se requiere un apikey, un token y un OTP válidos para realizar la petición.
    */
    async saveSpei (dataSpei: ISpei, otp: string ): Promise<GeneralResponse> {
        try {
            const { data } = await ConsumeService.post(`${URL_API.SPEI_SEND}/save`, dataSpei, { headers: { 'Kubit-OTP': `OTP ${otp}` } } );
            return {
                status: data.status,
                code: data.code
            };
    
        } catch (error: any) {
            return {
                status: error.message,
                code: 500,
                message: error?.message?.message || error?.data?.message || '',
            };
        }
    },


}