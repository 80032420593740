import { useEffect, useState } from 'react';
import './Tabs.scss';
import classNames from 'classnames';

interface Option {
    name: string,
    icon: string,
    page: string,
}

interface TabsProps {
    options: Option[],
    onChageTab: Function,
    showIcon?: boolean,
    defaultValue?: number | null,
    selectedOption?: string
    resetSelection?: boolean
};

/**
 * Componente Tab para facilitar la navegación entre diferentes vistas
 * @param {object} options Lista de opciones para el componete Tab
 */
export const Tabs = ({ options, onChageTab, showIcon = true, defaultValue = null, selectedOption, resetSelection = false }: TabsProps) => {

    const [itemSelected, setItemSelected] = useState<number | null>(defaultValue);

    useEffect(() => {
        if(resetSelection)
            setItemSelected(null);
    }, [resetSelection])

    /**
     * Cambia el estilo del item seleccionado para dejar el borde inferior con otro color
     * @param {number} index Indice del item seleccionado
     */
    const selectItem = (index: number, page: string) => {
        setItemSelected(index);
        onChageTab(page);
    };
    useEffect(() => {
        if(!selectedOption) return;
        const selectedIndex = options.findIndex(option => option.page === selectedOption);
        if(selectedIndex >=0 && selectedIndex != itemSelected ) setItemSelected(selectedIndex)
    }, [selectedOption])

    /**
     * Crea el item para el componente Tab
     * @param {object} option Carateristicas de la opcion (nombre e icono)
     * @param index Indice del arreglo de las opciones
     */
    const createItem = (option: Option, index: number) => {
        const itemClass = classNames('item', index === itemSelected ? 'selected' : '');
        const image = `/img/icons/tabs/${option.icon}${index === itemSelected ? 'Active' : 'Inactive'}.svg`;

        return (<div className={itemClass} key={`tab-item-${index}`} onClick={() => selectItem(index, option.page)}>
            {showIcon && <img src={image} alt='icon'/>}
            <p>{option.name}</p>
        </div>)
    };

    return (<div className='tabs-container w-full overflow-auto pb-2 lg:pb-0'>
        {options.map((option, index) => createItem(option, index))}
    </div>);
};
