import React from 'react';
const home = ({fill, stroke, className}) => {
    return (<><svg className={className} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/linear/home-2">
<g id="home-2">
<path id="Vector" d="M9.52 2.84001L4.13 7.04001C3.23 7.74001 2.5 9.23001 2.5 10.36V17.77C2.5 20.09 4.39 21.99 6.71 21.99H18.29C20.61 21.99 22.5 20.09 22.5 17.78V10.5C22.5 9.29001 21.69 7.74001 20.7 7.05001L14.52 2.72001C13.12 1.74001 10.87 1.79001 9.52 2.84001Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_2" d="M12.5 17.99V14.99" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
</svg>
</>)
}
export default home;
    