import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CUSTOM_SIDENAV_STYLE, MENUS } from '../../../../utils/constants';
import { AuthService } from '../../../../service';
import { useSpinner } from '../../../../hooks/useSpinner';
import classNames from 'classnames';
import '../../Sidenav.scss'
import { cleanSession } from '../../../../redux/slices/profileSlices';
import Icon from '../../../../assets/icon';
import { SubOption } from '../../model';

export const ItemSidenav = ({ name, icon, suboptions, menu, onSelect }:
    {name: string, icon: string, suboptions: SubOption[], menu: number, onSelect: Function}
) => {

    const [hiddenSubMenu, setHiddenSubMenu] = useState<boolean>(true);
    const [optionSelected, setOptionSelected] = useState<number>(MENUS.ACCOUNTS);
    const [loadingSpinner] = useSpinner();
    const dispatch = useDispatch();

    const selectMenu = async(menu: number) => {
        if(menu === MENUS.LOGIN) {
            loadingSpinner({loading: true});
            await AuthService.logout();
            dispatch(cleanSession());
            loadingSpinner({loading: false});
        }
        setOptionSelected(menu);
        onSelect(menu);

    };


    const createSubOption = ({ name, icon, submenu }: SubOption) => {
        const itemClass = classNames('item-suboption', submenu === optionSelected ? 'selected' : '');

        return (<div key={name} className={`${CUSTOM_SIDENAV_STYLE?.subMenuClasess.join(' ')} ${itemClass}`} onClick={() => selectMenu(submenu)}>
            <div className='container'>
            <Icon className={`${CUSTOM_SIDENAV_STYLE?.iconClasses.join(' ')} ${itemClass}`} iconName={`${icon}${submenu === optionSelected ? 'Active' : 'Inactive'}`}/>
                <p>{name}</p>
            </div>
        </div>);
    };


    if (suboptions.length > 0) {
        const subOp = suboptions.map(op => createSubOption({ name: op.name, icon: op.icon, submenu: op.submenu }));
        return (<React.Fragment key={name}>
            <div className={`${CUSTOM_SIDENAV_STYLE?.menuClasses.join(' ')} item-option ${hiddenSubMenu ? 'select-item' : ''}`} onClick={() => setHiddenSubMenu(!hiddenSubMenu)}>
                <div className='container with-submenu'>
                    <div className='with-submenu'>
                        <Icon  className={CUSTOM_SIDENAV_STYLE?.iconClasses.join(' ')} iconName={icon}/>
                        <p>{name}</p>
                    </div>
                    <Icon  className={`${CUSTOM_SIDENAV_STYLE?.iconClasses.join(' ')} ${!hiddenSubMenu ? 'rotate-chevron' : ''}`} iconName={'chevron'}/>
                </div>
            </div>
            {hiddenSubMenu && <div className={`suboption-container ${CUSTOM_SIDENAV_STYLE?.subMenuContainerClasses.join(' ')}`} id={`suboption-container-${name}`}>
                {subOp}
            </div>
            }
            
        </React.Fragment>);
    } else {
        
        return (<div className={`${CUSTOM_SIDENAV_STYLE?.menuClasses.join(' ')} item-option`} key={name}>
            <div className='container' onClick={() => selectMenu(menu)}>
                <Icon className={CUSTOM_SIDENAV_STYLE?.iconClasses.join(' ')}  iconName={icon}/>
                <p>{name}</p>
            </div>
        </div>);
    }
};
