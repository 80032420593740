import { useNavigate } from 'react-router-dom';
import './NavigationBar.scss';
import Icon from '../../assets/icon';

interface NavigationBarProps {
    title: string,
    previousPage?: string,
}

/**
 * Componente con el título de la página y con el boton de regresar
 * @param {string} title Título de la página actual
 * @param {string} previousPage Ruta de la página anterior
 */
export const NavigationBar = ({title, previousPage = ''}: NavigationBarProps) => {

    const navigate = useNavigate();

    return (<div className='navigation-bar-container pl-3 pr-3'>
        <h2>{title}</h2>
        {previousPage &&
            <div className='previous-page bg-backBg' onClick={() => navigate(previousPage)}>
                <Icon iconName='arrowLeft' className='stroke-backContrast' />
            </div>
        }
    </div>);
};
