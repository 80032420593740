import React from 'react';
const profile2usersInactive = ({fill, stroke, className}) => {
    return (<><svg className={className} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/linear/profile-2user">
<g id="profile-2user">
<path id="Vector" d="M9.65957 10.87C9.55957 10.86 9.43957 10.86 9.32957 10.87C6.94957 10.79 5.05957 8.84 5.05957 6.44C5.05957 3.99 7.03957 2 9.49957 2C11.9496 2 13.9396 3.99 13.9396 6.44C13.9296 8.84 12.0396 10.79 9.65957 10.87Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_2" d="M16.9103 4C18.8503 4 20.4103 5.57 20.4103 7.5C20.4103 9.39 18.9103 10.93 17.0403 11C16.9603 10.99 16.8703 10.99 16.7803 11" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_3" d="M4.65973 14.56C2.23973 16.18 2.23973 18.82 4.65973 20.43C7.40973 22.27 11.9197 22.27 14.6697 20.43C17.0897 18.81 17.0897 16.17 14.6697 14.56C11.9297 12.73 7.41973 12.73 4.65973 14.56Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_4" d="M18.8398 20C19.5598 19.85 20.2398 19.56 20.7998 19.13C22.3598 17.96 22.3598 16.03 20.7998 14.86C20.2498 14.44 19.5798 14.16 18.8698 14" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
</svg>
</>)
}
export default profile2usersInactive;
    