import { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { store } from './redux/store';
import { APP_NAME, PATHS, APP_TEXTS } from './utils/constants';
import './styles/fonts.scss';
import './App.scss';
import { Alert, Notification, Spinner } from './components';

const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ './components/NotFound'));
const LoginPage = lazy(() => import(/* webpackChunkName: "LoginPage" */ './pages/Login'));
const RecoverPasswordPage = lazy(() => import(/* webpackChunkName: "RecoverPasswordPage" */ './pages/Login/RecoverPasswordPage'));
const Accounts = lazy(() => import(/* webpackChunkName: "Accounts" */ './pages/Accounts/AccountsTable'));
const AccountDetails = lazy(() => import(/* webpackChunkName: "AccountDetails" */ './pages/Accounts/AccountDetails'));
const Movements = lazy(() => import(/* webpackChunkName: "Movements" */ './pages/Movements'));
const Transfers = lazy(() => import(/* webpackChunkName: "Transfers" */ './pages/Transfers'));
const eBankingPage = lazy(() => import(/* webpackChunkName: "eBankingPage" */ './pages/eBanking'));
const CustomerRegistration = lazy(() => import(/* webpackChunkName: "eBankingPage" */ './pages/CustomerRegistration'));
const LegalEntity = lazy(() => import(/* webpackChunkName: "eBankingPage" */ './pages/CustomerRegistration/LegalEntity'));
const NaturalPerson = lazy(() => import(/* webpackChunkName: "eBankingPage" */ './pages/CustomerRegistration/NaturalPerson'));


const Affilitaions = lazy(() => import(/* webpackChunkName: "eBankingPage" */ './pages/Affiliations'));

const App = () => {
    const favicon = document.getElementById("favicon") as HTMLLinkElement;
    favicon.href = `img/icons/${APP_NAME}-app-icon.svg`;
    const appleIcon = document.getElementById("apple-icon") as HTMLLinkElement;
    appleIcon.href = `img/icons/${APP_NAME}-app-icon.svg`;
     document.title = APP_TEXTS?.title || ''
    return (
        <div className='App'>
            <Suspense fallback={<h1>Cargando...</h1>}>
                <Provider store={store}>
                    <BrowserRouter>
                        <Routes>
                            <Route path={PATHS.login} Component={LoginPage} />
                            <Route path={PATHS.recoverPassword} Component={RecoverPasswordPage} />
                            <Route path={PATHS.eBanking} Component={eBankingPage}>
                                {/* Mis cuentas */}
                                <Route path={PATHS.accounts} Component={Accounts} />
                                <Route path={PATHS.accountDetails} Component={AccountDetails} >
                                    <Route path={PATHS.movements} Component={Movements} />
                                    <Route path={PATHS.transfers} Component={Transfers} />
                                    <Route path={PATHS.affiliations} Component={Affilitaions} />
                                </Route>
                                {/* Altas de clientes */}
                                <Route path={PATHS.customerRegistration} Component={CustomerRegistration} />
                                <Route path={PATHS.legalEntity} Component={LegalEntity} />
                                <Route path={PATHS.naturalPerson} Component={NaturalPerson} />
                            </Route>
                            <Route path='*' Component={NotFound} />
                        </Routes>
                    </BrowserRouter>
                    <Spinner />
                    <Notification />
                    <Alert/>
                </Provider>
            </Suspense>
            {/** this hidden div is needed as if not present the colors will not load correctly */}
            <div className='hidden'>
                <div className='bg-movementStatus-applied border-movementStatus-applied text-movementStatus-applied'></div>
                <div className='bg-movementStatus-stoped border-movementStatus-stoped text-movementStatus-stoped'></div>
                <div className='bg-movementStatus-created border-movementStatus-created text-movementStatus-created'></div>
                <div className='bg-movementStatus-pending border-movementStatus-pending text-movementStatus-pending'></div>
                <div className='bg-movementStatus-sent border-movementStatus-sent text-movementStatus-sent'></div>
                <div className='bg-movementStatus-in_transit border-movementStatus-in_transit text-movementStatus-in_transit'></div>
                <div className='bg-movementStatus-scattered border-movementStatus-scattered text-movementStatus-scattered'></div>
                <div className='bg-movementStatus-canceled border-movementStatus-canceled text-movementStatus-canceled'></div>
                <div className='bg-movementStatus-returned border-movementStatus-returned text-movementStatus-returned'></div>
            </div>
        </div>
    );
}

export default App;
