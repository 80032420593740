import React from 'react';
const gift = ({fill, stroke, className}) => {
    return (<><svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/linear/gift">
<g id="gift">
<path id="Vector" d="M26.2946 13.418H5.62793V23.7513C5.62793 27.6263 6.9196 28.918 10.7946 28.918H21.1279C25.0029 28.918 26.2946 27.6263 26.2946 23.7513V13.418Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_2" d="M28.2712 9.54036V10.832C28.2712 12.2529 27.5866 13.4154 25.6878 13.4154H6.31283C4.33658 13.4154 3.72949 12.2529 3.72949 10.832V9.54036C3.72949 8.11953 4.33658 6.95703 6.31283 6.95703H25.6878C27.5866 6.95703 28.2712 8.11953 28.2712 9.54036Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_3" d="M15.5349 6.95771H8.40493C7.96576 6.47979 7.97868 5.74354 8.44368 5.27854L10.2778 3.44438C10.7558 2.96646 11.5437 2.96646 12.0216 3.44438L15.5349 6.95771Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_4" d="M23.5821 6.95771H16.4521L19.9655 3.44438C20.4434 2.96646 21.2313 2.96646 21.7092 3.44438L23.5434 5.27854C24.0084 5.74354 24.0213 6.47979 23.5821 6.95771Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_5" d="M12.0479 13.418V20.0571C12.0479 21.0905 13.1845 21.6976 14.0499 21.1421L15.2641 20.3413C15.7033 20.0571 16.2587 20.0571 16.6849 20.3413L17.8345 21.1163C18.687 21.6846 19.8366 21.0776 19.8366 20.0442V13.418H12.0479Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
</svg>
</>)
}
export default gift;
    