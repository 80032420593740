import { AxiosRequestConfig } from 'axios';
import { setIsLoadingAxios } from '../redux/slices/spinnerSlices';
import axios from './interceptors/interceptors'
import { store } from '../redux/store'

export class ConsumeService {

  static dispatchLoading = (flag: boolean) => {
    store.dispatch(setIsLoadingAxios(flag));
  }

  static async get(url: string, config: AxiosRequestConfig = {}) {

    const { headers = {}, timeout = Number(process.env.REACT_APP_API_TIMEOUT), params = {} } = config;

    this.dispatchLoading(true);

    let response;

    try {
      response = await axios.get(url, {
        timeout,
        ...config,
        headers: {
          ...headers,
        },
        params
      });

    } catch (error) {
      console.log(error)
      throw error;
    } finally {
      this.dispatchLoading(false);
    }

    return response;
  }

  static async post(url: string, data: any, config: AxiosRequestConfig = {}) {

    const { headers = {}, timeout = Number(process.env.REACT_APP_API_TIMEOUT), params = {} } = config;

    this.dispatchLoading(true);

    let response;

    try {
      response = await axios.post(url, data, {
        ...config,
        timeout,
        headers: {
          ...headers,
        },
        params
      });
    } catch (error) {
      console.log(error)
      throw (error);
    } finally {
      this.dispatchLoading(false);
    }

    return response;
  }

  static async put(url: string, data: any, config: AxiosRequestConfig = {}) {

    const { headers = {}, timeout = Number(process.env.REACT_APP_API_TIMEOUT), params = {} } = config;

    this.dispatchLoading(true);

    let response;

    try {
      response = await axios.put(url, data, {
        ...config,
        timeout,
        headers: {
          ...headers,
        },
        params
      });
    } catch (error) {
      console.log(error)
      throw (error);
    } finally {
      this.dispatchLoading(false);
    }

    return response;
  }

  static async patch(url: string, data: any, config: AxiosRequestConfig = {}) {

    const { headers = {}, timeout = Number(process.env.REACT_APP_API_TIMEOUT), params = {} } = config;

    this.dispatchLoading(true);

    let response;

    try {
      response = await axios.patch(url, data, {
        ...config,
        timeout,
        headers: {
          ...headers,
        },
        params
      });
    } catch (error) {
      console.log(error)
      throw (error);
    } finally {
      this.dispatchLoading(false);
    }

    return response;
  }
  static async delete(url: string, data: any, config: AxiosRequestConfig = {}) {

    const { headers = {} } = config;

    this.dispatchLoading(true);

    let response;

    try {
      response = await axios.delete(url, {
        data,
        headers: {
          ...headers,
        }
      });
    } catch (error) {
      console.log(error)
      throw (error);
    } finally {
      this.dispatchLoading(false);
    }

    return response;
  }
}
