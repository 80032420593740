import React, {RefObject, useEffect, useRef} from 'react';
import { IMovements } from '../../interfaces/movementsInterface';
import { IBank } from '../../interfaces/transfersInterface';
import { formatDate } from '../../utils/functions';
import { APP_NAME, APP_TEXTS, MOVEMENT_STATUS, MOVEMENT_TYPE } from '../../utils/constants';

export interface BoucherParams {
    movement: IMovements;
    createPdf?: (ref: RefObject<HTMLDivElement>, param: any) => void
    banks: IBank[]
}

function randomIntFromInterval(min: number, max: number): number { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

export const Boucher = ({movement, createPdf, banks}: BoucherParams) => {

    const ref = useRef<HTMLDivElement>(null)
    
    useEffect(()=>{
        if(createPdf && movement){
            setTimeout(()=> {createPdf(ref, {filename: `${movement.tracking_key || movement.folio}.pdf`, page:{ margin: 6}})}, randomIntFromInterval(100,500))
        }
    },[movement])

    return (
        <div className='w-[740px] flex flex-col bg-neutral-1 p-3 self-center text-sm' ref={ref}>
            <div className='flex flex-row bg-primary p-3 text-neutral-1 items-center'>
                <div className='flex-1 flex py-3'>
                    <img className='h-[35px]' src={`/img/${APP_NAME}-boucher.png`} />
                </div>
                <div className='flex-1'>Comprobante de transaccion {MOVEMENT_TYPE[movement.type]}</div>
            </div>
            <section className='py-4'>
                <div className='border-b py-2 flex justify-start items-center'>
                    <p className='font-bold'>Resumen de transacción</p>
                </div>
                <div className='border-b py-2 flex justify-start items-center flex-row'>
                    <p className='flex-1 text-neutral-5 text-start'>Monto</p>
                    <p className='flex-1 font-bold text-start'>${movement.amount}</p>
                </div>
                <div className='border-b py-2 flex justify-start items-center flex-row'>
                    <p className='flex-1 text-neutral-5 text-start'>Tipo</p>
                    <p className='flex-1 font-bold text-start'>{movement.amount < 0 ? 'Decremento' : 'Incremento'}</p>
                </div>
                <div className='border-b py-2 flex justify-start items-center flex-row'>
                    <p className='flex-1 text-neutral-5 text-start'>Concepto</p>
                    <p className='flex-1 font-bold text-start'>{movement.payment_purpose}</p>
                </div>
                <div className='border-b py-2 flex justify-start items-center flex-row'>
                    <p className='flex-1 text-neutral-5 text-start'>Fecha</p>
                    <p className='flex-1 font-bold text-start'>{formatDate(movement.operation_date)}</p>
                </div>
                <div className='border-b py-2 flex justify-start items-center flex-row'>
                    <p className='flex-1 text-neutral-5 text-start'>Estatus</p>
                    <p className={`flex-1 font-bold text-start ${movement.status} [&.sent]:text-green-500 [&.applied]:text-green-500`}>{MOVEMENT_STATUS[movement.status as keyof typeof MOVEMENT_STATUS] || '-'}</p>
                </div>
                <div className='border-b py-2 flex justify-start items-center flex-row'>
                    <p className='flex-1 text-neutral-5 text-start'>Clave de rastreo</p>
                    <p className='flex-1 font-bold text-start'>{movement.tracking_key || movement.folio}</p>
                </div>
                <div className='border-b py-2 flex justify-start items-center flex-row'>
                    <p className='flex-1 text-neutral-5 text-start'>Referencia</p>
                    <p className='flex-1 font-bold text-start'>{movement.reference}</p>
                </div>
            </section>
            <section className='py-4'>
                <div className='border-b py-2 flex justify-start items-center'>
                    <p className='font-bold'>Ordenante</p>
                </div>
                {movement.payer_name && <div className='border-b py-2 flex justify-start items-center flex-row'>
                    <p className='flex-1 text-neutral-5 text-start'>Nombre</p>
                    <p className='flex-1 font-bold text-start'>{movement.payer_name}</p>
                </div>}
                {/* {<div className='border-b py-2 flex justify-start items-center flex-row'>
                    <p className='flex-1 text-neutral-5 text-start'>RFC</p>
                    <p className='flex-1 font-bold text-start'>-</p>
                </div>} */}
                {movement.payer_account && <div className='border-b py-2 flex justify-start items-center flex-row'>
                    <p className='flex-1 text-neutral-5 text-start'>Cuenta</p>
                    <p className='flex-1 font-bold text-start'>{movement.payer_account}</p>
                </div>}
                <div className='border-b py-2 flex justify-start items-center flex-row'>
                    <p className='flex-1 text-neutral-5 text-start'>Metodo de Pago</p>
                    <p className='flex-1 font-bold text-start'>{MOVEMENT_TYPE[movement.type]}</p>
                </div>
                {/* <div className='border-b py-2 flex justify-start items-center flex-row'>
                    <p className='flex-1 text-neutral-5 text-start'>CLABE</p>
                    <p className='flex-1 font-bold text-start'>{movement.payer_account}</p>
                </div> */}
                <div className='border-b py-2 flex justify-start items-center flex-row'>
                    <p className='flex-1 text-neutral-5 text-start'>Institución</p>
                    <p className='flex-1 font-bold text-start'>{banks.find(bank => bank.code === movement.origin_bank_id)?.name || `${APP_TEXTS?.transferName}`}</p>
                </div>
                
            </section>
            <section className='py-4'>
                <div className='border-b py-2 flex justify-start items-center'>
                    <p className='font-bold'>Beneficiario</p>
                </div>
                {movement.beneficiary_name && <div className='border-b py-2 flex justify-start items-center flex-row'>
                    <p className='flex-1 text-neutral-5 text-start'>Nombre</p>
                    <p className='flex-1 font-bold text-start'>{movement.beneficiary_name}</p>
                </div>}
                {/* <div className='border-b py-2 flex justify-start items-center flex-row'>
                    <p className='flex-1 text-neutral-5 text-start'>RFC</p>
                    <p className='flex-1 font-bold text-start'>-</p>
                </div> */}
                {movement.beneficiary_account && <div className='border-b py-2 flex justify-start items-center flex-row'>
                    <p className='flex-1 text-neutral-5 text-start'>Cuenta</p>
                    <p className='flex-1 font-bold text-start'>{movement.beneficiary_account}</p>
                </div>}
                <div className='border-b py-2 flex justify-start items-center flex-row'>
                    <p className='flex-1 text-neutral-5 text-start'>Metodo de Pago</p>
                    <p className='flex-1 font-bold text-start'>{MOVEMENT_TYPE[movement.type]}</p>
                </div>
                {/* <div className='border-b py-2 flex justify-start items-center flex-row'>
                    <p className='flex-1 text-neutral-5 text-start'>CLABE</p>
                    <p className='flex-1 font-bold text-start'>{movement.beneficiary_account}</p>
                </div> */}
                <div className='border-b py-2 flex justify-start items-center flex-row'>
                    <p className='flex-1 text-neutral-5 text-start'>Institución</p>
                    <p className='flex-1 font-bold text-start'>{banks.find(bank => bank.code === movement.destination_bank_id)?.name || `${APP_TEXTS?.transferName}`}</p>
                </div>
            </section>
            <div className='flex flex-row bg-primary p-3 text-neutral-1 items-center font-ArialBold text-lg'>
                <div className='flex-1 m-auto'>{APP_TEXTS?.boucherFooter} es tu medio de pago</div>
            </div>
        </div>
    )

}
