import React from 'react';
const importIcon = ({fill, stroke, className}) => {
    return (<><svg className={className} width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.2548 8.5293C19.7048 8.82638 21.1135 10.5993 21.1135 14.4805V14.6051C21.1135 18.8889 19.3981 20.6043 15.1144 20.6043H8.87563C4.59188 20.6043 2.87646 18.8889 2.87646 14.6051V14.4805C2.87646 10.628 4.26605 8.85513 7.65855 8.53888" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12 1.91699V14.2603" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.2104 12.123L12 15.3335L8.78955 12.123" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</>)
}
export default importIcon;
    