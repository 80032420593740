import { useDispatch } from 'react-redux';
import { loadSpinner } from '../redux/slices/spinnerSlices';
import { ISpinner } from '../interfaces/utilsInterface';

/**
 * Custom hook que controla el uso del spinner
 */
export const useSpinner = () => {

    const dispatch = useDispatch();

    /**
     * Funcion que se expone para controlar el encedido/apagado del spinner
     * @param {boolean} loading Bandera que prende/apaga el spinner
     * @param {strin} text Mensaje opcional que se muestra en la pantalla de espera
     */
    const loadingSpinner = ({loading, text}: ISpinner) => {
        dispatch(loadSpinner({ loading, text }));
    };

    return [ loadingSpinner ];
};
