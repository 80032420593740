import React from 'react';
const eye = ({fill, stroke, className}) => {
    return (<><svg className={className} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/outline/eye">
<g id="eye">
<path id="Vector" d="M12 16.8299C9.61001 16.8299 7.67001 14.8899 7.67001 12.4999C7.67001 10.1099 9.61001 8.16992 12 8.16992C14.39 8.16992 16.33 10.1099 16.33 12.4999C16.33 14.8899 14.39 16.8299 12 16.8299ZM12 9.66992C10.44 9.66992 9.17001 10.9399 9.17001 12.4999C9.17001 14.0599 10.44 15.3299 12 15.3299C13.56 15.3299 14.83 14.0599 14.83 12.4999C14.83 10.9399 13.56 9.66992 12 9.66992Z" fill={fill}/>
<path id="Vector_2" d="M12 21.52C8.23999 21.52 4.68999 19.32 2.24999 15.5C1.18999 13.85 1.18999 11.16 2.24999 9.49998C4.69999 5.67998 8.24999 3.47998 12 3.47998C15.75 3.47998 19.3 5.67998 21.74 9.49998C22.8 11.15 22.8 13.84 21.74 15.5C19.3 19.32 15.75 21.52 12 21.52ZM12 4.97998C8.76999 4.97998 5.67999 6.91998 3.51999 10.31C2.76999 11.48 2.76999 13.52 3.51999 14.69C5.67999 18.08 8.76999 20.02 12 20.02C15.23 20.02 18.32 18.08 20.48 14.69C21.23 13.52 21.23 11.48 20.48 10.31C18.32 6.91998 15.23 4.97998 12 4.97998Z" fill={fill}/>
</g>
</g>
</svg>
</>)
}
export default eye;
    