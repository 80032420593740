
import AddCustomer from './icons/addCustomer';
import ArrowDown from './icons/arrowDown';
import ArrowLeft from './icons/arrowLeft';

import ArrowRight from './icons/arrowRight';
import CalendarEdit from './icons/calendarEdit';
import Calendar from './icons/calendar';

import Chart from './icons/chart';
import Check from './icons/check';
import Chevron from './icons/chevron';

import Close from './icons/close';
import Copy from './icons/copy';
import Cross from './icons/cross';

import DocumentText from './icons/documentText';
import DocumentUpload from './icons/documentUpload';
import EditProfile from './icons/editProfile';

import Edit from './icons/edit';
import Error from './icons/error';
import Export from './icons/export';

import Eye from './icons/eye';
import Filters from './icons/filters';
import Help from './icons/help';

import Import from './icons/import';
import Link from './icons/link';
import LivingrockAppIcon from './icons/livingrockAppIcon';

import Logout2 from './icons/logout2';
import Cards from './icons/cards';
import Gift from './icons/gift';

import Receipt from './icons/receipt';
import Shop from './icons/shop';
import MasefeAppIcon from './icons/masefeAppIcon';

import Menu from './icons/menu';
import Money from './icons/money';
import More from './icons/more';

import Notification from './icons/notification';
import PersonalCard from './icons/personalCard';
import Schedule from './icons/schedule';

import SearchNormal from './icons/searchNormal';
import Search from './icons/search';
import Security from './icons/security';

import AddCustomerActive from './icons/addCustomerActive';
import AddCustomerInactive from './icons/addCustomerInactive';
import Briefcase from './icons/briefcase';

import Home from './icons/home';
import Logout from './icons/logout';
import Profile2usersActive from './icons/profile2usersActive';

import Profile2usersInactive from './icons/profile2usersInactive';
import Success from './icons/success';
import Movementsactive from './icons/movementsactive';

import Movementsinactive from './icons/movementsinactive';
import Walletactive from './icons/walletactive';
import Walletinactive from './icons/walletinactive';

import View from './icons/view';
import Warning from './icons/warning';
import Kubitq from './icons/kubitq';

import Livingrock from './icons/livingrock';
import Logo from './icons/logo';
import Masefe from  './icons/masefe'


const Icon = ({iconName, ...props}) => {
    switch (iconName){
    case 'addCustomer':
        return <AddCustomer {...props} />
    case 'arrowDown':
        return <ArrowDown {...props} />
    case 'arrowLeft':
        return <ArrowLeft {...props} />
    case 'arrowRight':
        return <ArrowRight {...props} />
    case 'calendarEdit':
        return <CalendarEdit {...props} />
    case 'calendar':
        return <Calendar {...props} />
    case 'chart':
        return <Chart {...props} />
    case 'check':
        return <Check {...props} />
    case 'chevron':
        return <Chevron {...props} />
    case 'close':
        return <Close {...props} />
    case 'copy':
        return <Copy {...props} />
    case 'cross':
        return <Cross {...props} />
    case 'documentText':
        return <DocumentText {...props} />
    case 'documentUpload':
        return <DocumentUpload {...props} />
    case 'editProfile':
        return <EditProfile {...props} />
    case 'edit':
        return <Edit {...props} />
    case 'error':
        return <Error {...props} />
    case 'export':
        return <Export {...props} />
    case 'eye':
        return <Eye {...props} />
    case 'filters':
        return <Filters {...props} />
    case 'help':
        return <Help {...props} />
    case 'import':
        return <Import {...props} />
    case 'link':
        return <Link {...props} />
    case 'livingrockAppIcon':
        return <LivingrockAppIcon {...props} />
    case 'logout2':
        return <Logout2 {...props} />
    case 'cards':
        return <Cards {...props} />
    case 'gift':
        return <Gift {...props} />
    case 'receipt':
        return <Receipt {...props} />
    case 'shop':
        return <Shop {...props} />
    case 'masefeAppIcon':
        return <MasefeAppIcon {...props} />
    case 'menu':
        return <Menu {...props} />
    case 'money':
        return <Money {...props} />
    case 'more':
        return <More {...props} />
    case 'notification':
        return <Notification {...props} />
    case 'personalCard':
        return <PersonalCard {...props} />
    case 'schedule':
        return <Schedule {...props} />
    case 'searchNormal':
        return <SearchNormal {...props} />
    case 'search':
        return <Search {...props} />
    case 'security':
        return <Security {...props} />
    case 'addCustomerActive':
        return <AddCustomerActive {...props} />
    case 'addCustomerInactive':
        return <AddCustomerInactive {...props} />
    case 'briefcase':
        return <Briefcase {...props} />
    case 'home':
        return <Home {...props} />
    case 'logout':
        return <Logout {...props} />
    case 'profile2usersActive':
        return <Profile2usersActive {...props} />
    case 'profile2usersInactive':
        return <Profile2usersInactive {...props} />
    case 'success':
        return <Success {...props} />
    case 'movementsactive':
        return <Movementsactive {...props} />
    case 'movementsinactive':
        return <Movementsinactive {...props} />
    case 'walletactive':
        return <Walletactive {...props} />
    case 'walletinactive':
        return <Walletinactive {...props} />
    case 'view':
        return <View {...props} />
    case 'warning':
        return <Warning {...props} />
    case 'kubitq':
        return <Kubitq {...props} />
    case 'livingrock':
        return <Livingrock {...props} />
    case 'logo':
        return <Logo {...props} />
    case 'masefe':
        return <Masefe {...props} />
    default: 
        return <></>
    }
}

export default Icon;