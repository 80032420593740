import { useEffect, useState } from 'react';
import './Paginate.scss';

interface PaginateProps {
    itemsPerPage: number,
    length: number,
    onPageChange: Function,
    current?: number
}

/**
 * Componente que maneja la lógica de un paginador
 * En el evento onPageChange se responde el índice del elemento inicial de la página
 * @param {number} itemsPerPage Número de elementos por página
 * @param {number} length Número total de elementos
 * @param {Function} onPageChange Funcion que retorna el indice del elemento inicial de la página
 */
export const Paginate = ({ itemsPerPage, length, onPageChange, current  }: PaginateProps) => {

    const [lastPage, setLastPage] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);

    /**
     * Raliza el cambio de pagina ya sea incrementando o decrementando el valor
     * @param {string} newPage Indicador para saber si quiere incrementar o decrementar el paginador 
     */
    const changePage = (newPage: 'left' | 'right' ) => {
        // Si retrocede el paginado
        if (newPage === 'left' && currentPage !== 1) {
            setCurrentPage(currentPage - 1);

        } else if (newPage === 'right' && currentPage !== lastPage){ // Se avanza el paginado
            setCurrentPage(currentPage + 1);
        }
    };

    // Regresa el indice del elemento inicial de la página
    useEffect(() => {
        let start = currentPage - 1;
        onPageChange(start * itemsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentPage]);

    /**
     * Actualiza los parametros de ultima pagina y el total de elementos cuando hay un cambio el la longitud
     * del total de elementos
     */
    useEffect(() => {
        let lastPageTmp = Math.ceil(length / itemsPerPage);
        setLastPage(lastPageTmp);
        setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [length]);

    return(<div className='paginate-container'>
        <img src={'/img/icons/arrow-left.svg'} alt='arrowLeft' onClick={() => changePage('left')}/>
        <div className='counter'>
            <p>{currentPage}</p>
            <p>/</p>
            <p>{lastPage}</p>
        </div>
        <img src={'/img/icons/arrow-right.svg'} alt='arrowRight' onClick={() => changePage('right')}/>
    </div>);
};
