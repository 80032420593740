import React from 'react';
const edit = ({fill, stroke, className}) => {
    return (<><svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.33398 1.33301H6.00065C2.66732 1.33301 1.33398 2.66634 1.33398 5.99967V9.99967C1.33398 13.333 2.66732 14.6663 6.00065 14.6663H10.0007C13.334 14.6663 14.6673 13.333 14.6673 9.99967V8.66634" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.6933 2.0135L5.43992 7.26684C5.23992 7.46684 5.03992 7.86017 4.99992 8.14684L4.71325 10.1535C4.60659 10.8802 5.11992 11.3868 5.84659 11.2868L7.85325 11.0002C8.13325 10.9602 8.52659 10.7602 8.73325 10.5602L13.9866 5.30684C14.8933 4.40017 15.3199 3.34684 13.9866 2.0135C12.6533 0.680168 11.5999 1.10684 10.6933 2.0135Z" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.93945 2.7666C10.3861 4.35993 11.6328 5.6066 13.2328 6.05993" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</>)
}
export default edit;
    