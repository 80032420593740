import React, { ChangeEvent, DragEvent as ReactDragEvent, useRef } from "react";
import "./DropArea.scss";
interface DropAreaProps {
  onNewFile?: (file: File) => void;
  label?: string;
}

export const DropArea = ({ onNewFile, label }: DropAreaProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFile = (e: any) => {
    const input: FileList = e.files;
    onNewFile?.(input?.[0]);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    
  }

  const preventEvents = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  }
  return (
    <div
      className="drop-area w-fill"
      onDrop={(e) => {
        preventEvents(e)
        handleFile(e.dataTransfer)
      }}
      onDragOver={(e) => preventEvents(e)}
      onClick={() => fileInputRef?.current?.click()}
    >
      <div className="drop-label flex flex-row">
        <img src={"/img/icons/import.svg"} alt="edit" />
        <p className="text-[14px]">
          {label || "Arrastra el archivo o selecciona desde tus documentos"}
        </p>
        <input
          className="hidden"
          type="file"
          max={1}
          ref={fileInputRef}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleFile(e.target) }
        />
      </div>
    </div>
  );
};

