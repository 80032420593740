import { CEPResponse, IMovements, MovementsPagedRequest, MovementsResponse } from '../../interfaces/movementsInterface'
import { ConsumeService } from '../ConsumeService';
import { URL_API } from '../../utils/constants';
import { saveAs } from 'file-saver';
import { store } from "../../redux/store";
import { setAlert } from '../../redux/slices/alertSlice';

const formatDateQueryParams = (startDate: string) => {
    const [year, month, day] = startDate.split('-').map(Number);
    const date = new Date(year, month - 1, day);
  
    const formattedDay = String(date.getDate()).padStart(2, '0');
    const formattedMonth = String(date.getMonth() + 1).padStart(2, '0');
    const formattedYear = date.getFullYear();
  
    return `${formattedDay}/${formattedMonth}/${formattedYear}`;
}
  

export const MovementsService =  {
  
    /**
     * Obtiene los últimos movimientos de una cuenta
     * @param accountId Identificador de la cuenta
     */
    async getLatestMovements (accountId: string): Promise<MovementsResponse>{
        try {
            const url = URL_API.ACCOUNTS_MOVEMENTS_LATEST.replace("#", accountId);
            const { data } = await ConsumeService.get(url);
            return {
                data: data.data ?? [],
                total: data.total ?? 0
            }
      
          } catch (error) {
            return {
                data: [],
                total: 0
            }
          }
    },

    /**
     * Obtiene los últimos movimientos de una cuenta
     * @param {string} accountId Identificador interno de la cuenta
     * @param {number} limit Numero de cuentas por peticion
     * @param {number} offset Numero de pagina
     * @param {number} startDate Fecha de operación inicial
     * @param {number} endDate Fecha de operación final
     */
    async getMovementsPagedApi ({account_id, limit, offset, startDate, endDate}: MovementsPagedRequest): Promise<MovementsResponse> {
        try {
            let filters = '';

            if (startDate) {
                filters += `&startDate=${formatDateQueryParams(startDate)}`;
            }

            if (endDate) {
                filters += `&endDate=${formatDateQueryParams(endDate)}`;
            }

            const queryParams = `?limit=${limit}&offset=${offset}${filters}`;
            const url = `${URL_API.ACCOUNTS_MOVEMENTS_PAGED}${queryParams}`;
            const endUrl =  url.replace("#", account_id);

            const { data } = await ConsumeService.get(endUrl);
            return {
                data: data.data || [],
                total: data.total,
            };

        } catch (error) {
            return {
                data: [],
                total: 0
            }
        }
    },

    async getCep(movement_id: string): Promise<CEPResponse> {
        try {
            const url = `${URL_API.SPEI_SEND}/orders/${movement_id}/cep`;

            const { data } = await ConsumeService.get(url);
            return {
                status: "ok",
                code: 200,
                data: data.data,
            };

        } catch (error: any) {
            return {
                status: error.message,
                code: 500,
                data: "",
            };
        }
    },

    async getMovement(movement_id: string): Promise<IMovements | null> {
        try {
            const url = `${URL_API.ACCOUNTS_MOVEMENTS}/${movement_id}`;

            const { data } = await ConsumeService.get(url);
            return data.data;

        } catch (error) {
            return null;
        }
    },

    async getMonthlyReport(account_id: string, account_type: number): Promise<void> {
        try {
            const url = `${URL_API.MONTHLY_REPORT}/${account_id}/current/${account_type}`;
            
            const { data, headers } = await ConsumeService.get(url, { timeout: 10000 * 6, responseType: 'arraybuffer' });
            const blob = new Blob([data], {type: headers['Content-Type'] as string || 'application/pdf'});
            saveAs(blob, "Estado de Movimientos.pdf");

        } catch (error) {
            store.dispatch(setAlert({text: 'Ocurrio un error al intentar generar el archivo', show: true, severity: 'error'}));
        }
    },

    async getMovementsReport(account_id: string, account_type: number, filters: string): Promise<void> {
        try {
            const url = `${URL_API.MOVEMENTS_REPORT}/${account_id}/${account_type}?${filters}`;
            
            const { data, headers } = await ConsumeService.get(url, { timeout: 10000 * 6, responseType: 'arraybuffer' });
            const blob = new Blob([data], {type: headers['Content-Type'] as string || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            saveAs(blob, "Reporte de Movimientos.xlsx")

        } catch (error) {
            store.dispatch(setAlert({text: 'Ocurrio un error al intentar generar el archivo', show: true, severity: 'error'}));
        }
    }

}