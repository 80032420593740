import React from 'react';
const chevron = ({fill, stroke, className}) => {
    return (<><svg className={className} width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id=" icon &#34;chevron right&#34;">
<path id="Vector" d="M13.5 7L7.5 0.999999L1.5 7" stroke={stroke} strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
</g>
</svg>
</>)
}
export default chevron;
    