import React from 'react';
const arrowLeft = ({fill, stroke, className}) => {
    return (<><svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/linear/arrow-left">
<g id="arrow-left">
<path id="Vector" d="M9.99999 13.2802L5.65333 8.93355C5.13999 8.42021 5.13999 7.58021 5.65333 7.06688L9.99999 2.72021" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
</svg>
</>)
}
export default arrowLeft;
    