import {ItemSidenav} from './components';
import './Sidenav.scss';
import { APP_NAME, APP_TEXTS, CUSTOM_SIDENAV_STYLE } from '../../utils/constants';
import { Menus } from './model';

export const Sidenav = ({ options, onSelect }: {options: Menus, onSelect: Function}) => {

    
    return (<div className={`sidenav-component hidden lg:block ${CUSTOM_SIDENAV_STYLE?.classes?.join(' ')} h-full flex min-h-[580px] z-10`}>
        <img src={`${CUSTOM_SIDENAV_STYLE?.menuSmImg || `/img/logo/${APP_NAME}.svg`}`} alt={APP_TEXTS?.displayName} className={`block lg:hidden py-4 w-full ${CUSTOM_SIDENAV_STYLE?.menuSmImgClass || ''}`}/>
        {
            options.map((option, index) => <ItemSidenav
            key={`${option.name}-${index}`}
                name={option.name}
                icon={option.icon}
                suboptions={option.suboptions? option.suboptions : []}
                menu={option.menu? option.menu : 0}
                onSelect={(e: number) => onSelect(e)}
            />)
        }
       { CUSTOM_SIDENAV_STYLE?.img && <div className='self-end mt-auto relative h-full w-full'><img src={CUSTOM_SIDENAV_STYLE?.img} alt={APP_TEXTS?.displayName} className='absolute right-0 bottom-0 img-sidemenu lg:w-full w-32 mr-2 lg:mr-0'/></div>}
    </div>);
};
