import React from 'react';
const movementsactive = ({fill, stroke, className}) => {
    return (<><svg className={className} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/outline/activity">
<g id="activity">
<path id="Vector" d="M10.625 16.1149H6.37499C4.50926 16.1149 3.29689 15.7144 2.54141 14.9589C1.78592 14.2034 1.38541 12.9911 1.38541 11.1253V6.87533C1.38541 5.0096 1.78592 3.79723 2.54141 3.04174C3.29689 2.28626 4.50926 1.88574 6.37499 1.88574H10.625C12.4907 1.88574 13.7031 2.28626 14.4586 3.04174C15.2141 3.79723 15.6146 5.0096 15.6146 6.87533V11.1253C15.6146 12.9911 15.2141 14.2034 14.4586 14.9589C13.7031 15.7144 12.4907 16.1149 10.625 16.1149ZM6.37499 1.94824C4.70701 1.94824 3.42733 2.23973 2.58336 3.0837C1.73939 3.92767 1.44791 5.20734 1.44791 6.87533V11.1253C1.44791 12.7933 1.73939 14.073 2.58336 14.917C3.42733 15.7609 4.70701 16.0524 6.37499 16.0524H10.625C12.293 16.0524 13.5726 15.7609 14.4166 14.917C15.2606 14.073 15.5521 12.7933 15.5521 11.1253V6.87533C15.5521 5.20734 15.2606 3.92767 14.4166 3.0837C13.5726 2.23973 12.293 1.94824 10.625 1.94824H6.37499Z" fill={fill} stroke={stroke}/>
<path id="Vector_2" d="M5.17517 10.7883L5.17522 10.7882L5.16819 10.7829C5.16455 10.7801 5.16276 10.7777 5.16157 10.7757C5.16018 10.7732 5.1589 10.7699 5.15834 10.7656C5.15725 10.7573 5.15901 10.7487 5.1651 10.7403L6.8476 8.5559C6.9745 8.39473 7.14741 8.29554 7.34499 8.27032L7.34499 8.27032L7.34634 8.27014C7.53717 8.24525 7.73585 8.29902 7.89372 8.4226C7.89384 8.42269 7.89395 8.42278 7.89407 8.42287L9.18367 9.43764C9.36116 9.58414 9.55395 9.59976 9.68768 9.58226C9.85548 9.56687 10.0273 9.48022 10.1507 9.31796L11.7852 7.20942L11.7852 7.20944L11.7886 7.20502C11.7913 7.20142 11.7961 7.19762 11.8049 7.19652C11.8091 7.196 11.8124 7.19644 11.8145 7.19699C11.816 7.19742 11.8171 7.19796 11.8185 7.19907L11.8251 7.20443L11.8318 7.20956C11.8355 7.21233 11.8371 7.21458 11.838 7.21617C11.8391 7.21808 11.84 7.22059 11.8404 7.22365C11.8408 7.22671 11.8406 7.22992 11.8397 7.23308C11.8389 7.2359 11.8373 7.24 11.8331 7.24532L11.8331 7.24532L11.8307 7.24843L10.1961 9.35715C10.1958 9.35746 10.1956 9.35778 10.1953 9.35809C10.068 9.52004 9.89775 9.61571 9.70795 9.6359L9.70794 9.63579L9.69753 9.63712C9.49926 9.66243 9.30706 9.60875 9.14877 9.48482C9.14866 9.48473 9.14855 9.48465 9.14845 9.48457L7.85869 8.46968C7.68799 8.32905 7.49352 8.30559 7.3498 8.32566C7.18334 8.34234 7.01347 8.42912 6.89123 8.59024L5.2068 10.7772L5.1946 10.793L5.19347 10.7947C5.19303 10.7948 5.19257 10.7948 5.19209 10.7948C5.18776 10.7948 5.18543 10.7941 5.18427 10.7937C5.18334 10.7934 5.18039 10.7924 5.17517 10.7883Z" fill={fill} stroke={stroke}/>
</g>
</g>
</svg>
</>)
}
export default movementsactive;
    