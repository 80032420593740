import React from 'react';
const addCustomerActive = ({fill, stroke, className}) => {
    return (<><svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/linear/profile-tick">
<g id="profile-tick">
<path id="Vector" d="M14.9404 19.0512L16.4604 20.5712L19.5004 17.5312" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_2" d="M12.6596 10.87C12.5596 10.86 12.4396 10.86 12.3296 10.87C9.94961 10.79 8.05961 8.84 8.05961 6.44C8.04961 3.99 10.0396 2 12.4896 2C14.9396 2 16.9296 3.99 16.9296 6.44C16.9296 8.84 15.0296 10.79 12.6596 10.87Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_3" d="M12.49 21.8097C10.67 21.8097 8.86004 21.3497 7.48004 20.4297C5.06004 18.8097 5.06004 16.1697 7.48004 14.5597C10.23 12.7197 14.74 12.7197 17.49 14.5597" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
</svg>
</>)
}
export default addCustomerActive;
    