import axios from "axios";
import { store } from "../../redux/store";
import { URL_API } from "../../utils/constants";
import { cleanSession } from "../../redux/slices/profileSlices";

axios.interceptors.request.use(
    (config) => {
        config.withCredentials = true;
        if (
        config.url === URL_API.AUTH_LOGIN ||
        config.url === URL_API.AUTH_AUTHUSER
        ) {
        return config;
        } else {
        config.headers["Authorization"] = `Bearer ${
            store.getState().user.profile.token
        }`;

        return config;
        }
    },
    (error) => {
        Promise.reject(error as Error);
    }
);

axios.interceptors.response.use(null, (error) => {
    const url = error.request.responseURL;
    const { status } = error;
    if (url === URL_API.AUTH_LOGIN || url === URL_API.AUTH_AUTHUSER) {
        return Promise.reject(error as Error);
    }

    if (status === 401 && !error?.response?.data?.includes?.('otp')) store.dispatch(cleanSession());
    return Promise.reject(error as Error);
});

export default axios;
