export enum TypeStatus {
    PENDING = 'Pendiente',
    SUCCESS = 'Procesada',
    ERROR = 'Fallida',
}

export const ID_STATUS: { [key in string]?: any } = {
    [TypeStatus.PENDING]:       '1',
    [TypeStatus.SUCCESS]:       '2',
    [TypeStatus.ERROR]:         '3',
}

export const TYPE_STATUS_BADGE: { [key in string]?: any } = {
    [TypeStatus.PENDING]:       'pending',
    [TypeStatus.SUCCESS]:       'applied',
    [TypeStatus.ERROR]:         'canceled',
}

export enum TypePaymentMethod {
    SPEI = 'SPEI'
}

export interface IPendingTransfers {
    id: string,
    alias_beneficiary_account: string,
    amount: number,
    beneficiary_account: string,
    beneficiary_account_type: number,
    beneficiary_bank: string,
    beneficiary_email: string,
    beneficiary_name: string,
    beneficiary_uid: string,
    concept: string,
    numerical_reference: number,
    payer_account: string,
    save_beneficiary_account: boolean,
    status?: TypeStatus,
    payment_method: TypePaymentMethod
}