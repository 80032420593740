import { ICustomer } from "../../interfaces/customerInterface";
import { URL_API } from "../../utils/constants";
import { ConsumeService } from "../ConsumeService";

export const PartnersService = {

    async getPartnerById(id: string): Promise<ICustomer | null> {
        try {
            const url = `${URL_API.PARTNERS}/${id}`;

            const { data } = await ConsumeService.get(url);
            return data.data || null;

        } catch (error) {
            return null;
        }
    }
}