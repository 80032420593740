import { ChangeEvent } from "react";
import "./Selector.scss";

interface Option {
  value: string;
  text: string;
}

interface SelectorFormProps {
  label: string;
  options: Option[];
  onSelect: Function;
  defaultValue?: string;
}

/**
 * Componente Selector para formularios
 * @param {string} label Titulo del selector
 * @param {array} options Lista de opciones del selector
 * @param {function} onSelect Funcion que regresa el valor seleccionado del selector
 */
export const Selector = ({
  label,
  options,
  onSelect,
  defaultValue,
}: SelectorFormProps) => {
  /**
   * Crea las opciones para el selector
   * @param {string} value Valor logico
   * @param {string} text Leyenda de la opcion
   * @param {number} index Indice del arreglo, se usa para asignar un identificador a la opcion
   */
  const createOptions = ({ value, text }: Option, index: number) => {
    return (
      <option value={value} key={`option-${index}`}>
        {text}
      </option>
    );
  };

  return (
    <div className="selector-form">
      <label>{label}</label>
      <select
        defaultValue={defaultValue || "default"}
        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
          onSelect({ value: e.target.value, text: "texto" })
        }
      >
        <option value={"default"} disabled>
          Selecciona una opción
        </option>
        {options.map((option: Option, index: number) =>
          createOptions(option, index)
        )}
      </select>
    </div>
  );
};
