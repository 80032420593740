import classNames from 'classnames';
import { useState } from 'react';

type InputType = 'text' | 'password';

interface InputLoginProps {
    type: InputType,
    label: string,
    placeholder: string,
    onTextChange: Function,
    error?: boolean,
}

/**
 * Componente para el Input de login
 * @param {string} type Tipo de Input: texto o contraseña
 * @param {string} label Leyenda que se muestra en la parte superior
 * @param {string} placeholder Leyenda de ayuda
 * @param {Function} onTextChange Función que se detona cuando hay un cambio de texto
 * @param {boolean} error Bandera que indica el estilo de error
 */
export const InputFormLogin = ({ label, placeholder, type, onTextChange, error }: InputLoginProps) => {

    const inputClass = classNames('input-login-form', { error });
    const [inputType, setInputType] = useState<InputType>(type);

    return (<div className={inputClass}>
        <label>{label}</label>
        <div>
            <input type={inputType} placeholder={placeholder} onChange={e => onTextChange(e.target.value)} />
            {type === 'password' &&
                <img
                    src={'/img/icons/eye.svg'}
                    alt='eye'
                    onMouseDown={() => setInputType('text')}
                    onMouseUp={() => setInputType('password')}
                />
            }
        </div>
    </div>);
};
