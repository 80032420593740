import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { IUserProfile } from "../../interfaces/userProfileInterface";
import { ICustomer } from "../../interfaces/customerInterface";
import {
  SESSION_CUSTOMER_KEY,
  SESSION_K_KEY,
  SESSION_PROFILE_KEY,
  SESSION_PVK_KEY,
  SESSION_TOKEN_KEY,
} from "../../utils/constants";
import { IAccountInfo } from '../../interfaces/accountsInterface';

const address = {
  street: "",
  num_ext: "",
  num_int: "",
  reference: "",
  neighborhood: "",
  district: "",
  estate: "",
  cp: "",
};

const initialState = {
  current_customer: {
    id: '',
    rfc: "",
    parent_id: "",
    account_customer_id: "",
    level: 0,
    company_name: "",
    name: "",
    ap_paterno: "",
    ap_materno: "",
    taxpayer_type_id: 0,
    address: address,
    company_address: address,
    contact_name: "",
    contact_email: "",
    contact_tel: "",
    creation_date: "",
    account: {
        id: '',
        type: 0,
        amount: 0,
        currency: '',
        linked_cellphone: '',
        creation_date: '',
        customer_id: '',
        clabes: [{
            account_id: '',
            clabe: '',
            id: 0,
            payment_provider_id: 0,
        }],
    }
  },
  profile: {
    customer_type: 0,
    id: 0,
    profile: "",
    profile_id: 0,
    role: "",
    role_id: 0,
    username: "",
    customer_id: "",
    token: "",
    pvk: '',
    key: '',
  },
  information: {
    rfc: "",
    parent_id: "",
    account_customer_id: "",
    level: 0,
    company_name: "",
    name: "",
    ap_paterno: "",
    ap_materno: "",
    taxpayer_type_id: 0,
    address: address,
    company_address: address,
    contact_name: "",
    contact_email: "",
    contact_tel: "",
    creation_date: "",
  },
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<IUserProfile & {pvk: string; key: string;}>) => {
      state.profile = action.payload;
      const { token, customer_id,pvk, key, ...profile } = action.payload;
      if (token) sessionStorage.setItem(SESSION_TOKEN_KEY, token);
      if (customer_id)
        sessionStorage.setItem(SESSION_CUSTOMER_KEY, customer_id);
      if (profile)
        sessionStorage.setItem(SESSION_PROFILE_KEY, JSON.stringify(profile));
      if (pvk)
        sessionStorage.setItem(SESSION_PVK_KEY, pvk);
      if (key)
        sessionStorage.setItem(SESSION_K_KEY, key);
    },
    cleanSession: (state) => {
      state.profile = initialState.profile;
      state.information = initialState.information;
      sessionStorage.removeItem(SESSION_TOKEN_KEY);
      sessionStorage.removeItem(SESSION_CUSTOMER_KEY);
      sessionStorage.removeItem(SESSION_PROFILE_KEY);
      sessionStorage.removeItem(SESSION_PVK_KEY);
      sessionStorage.removeItem(SESSION_K_KEY);
    },
    setCustomerInfo: (state, action: PayloadAction<ICustomer | null>) => {
      if (action.payload) {
        state.information.rfc = action.payload.rfc;
        state.information.parent_id = action.payload.parent_id
          ? action.payload.parent_id
          : "";
        state.information.account_customer_id = action.payload
          .account_customer_id
          ? action.payload.account_customer_id
          : "";
        state.information.level = action.payload.level
          ? action.payload.level
          : 0;
        state.information.company_name = action.payload.company_name
          ? action.payload.company_name
          : "";
        state.information.name = action.payload.name ? action.payload.name : "";
        state.information.ap_paterno = action.payload.ap_paterno
          ? action.payload.ap_paterno
          : "";
        state.information.ap_materno = action.payload.ap_materno
          ? action.payload.ap_materno
          : "";
        state.information.taxpayer_type_id = action.payload.taxpayer_type_id;
        state.information.address = action.payload.address
          ? action.payload.address
          : state.information.address;
        state.information.contact_name = action.payload.contact_name;
        state.information.contact_email = action.payload.contact_email;
        state.information.contact_tel = action.payload.contact_tel;
        state.information.creation_date = action.payload.creation_date;
      }
    },
    setPartnerInfo: (state, action: PayloadAction<ICustomer | null>) => {
      if (action.payload) {
        state.information.rfc = action.payload.rfc;
        state.information.company_name = action.payload.company_name
          ? action.payload.company_name
          : "";
        state.information.taxpayer_type_id = action.payload.taxpayer_type_id;
        state.information.company_address = action.payload.company_address
          ? action.payload.company_address
          : state.information.company_address;
        state.information.contact_name = action.payload.contact_name;
        state.information.contact_name = action.payload.contact_name;
        state.information.contact_email = action.payload.contact_email;
        state.information.contact_tel = action.payload.contact_tel;
        state.information.creation_date = action.payload.creation_date;
      }
    },
    setCurrentCustomer: (state, action: PayloadAction<Omit<IAccountInfo,"nestedAccounts">>) =>{
      if (action.payload) {
        state.current_customer.id = action.payload.id || '';
        state.current_customer.rfc = action.payload.rfc;
        state.current_customer.parent_id = action.payload.parent_id || '';
        state.current_customer.account_customer_id = action.payload
          .account_customer_id || '';
        state.current_customer.level = action.payload.level || 0;
        state.current_customer.company_name = action.payload.company_name || ''
        state.current_customer.name = action.payload.name ||  "";
        state.current_customer.ap_paterno = action.payload.ap_paterno || "";
        state.current_customer.ap_materno = action.payload.ap_materno || "";
        state.current_customer.taxpayer_type_id = action.payload.taxpayer_type_id;
        state.current_customer.address = action.payload.address || state.current_customer.address;
        state.current_customer.contact_name = action.payload.contact_name;
        state.current_customer.contact_email = action.payload.contact_email;
        state.current_customer.contact_tel = action.payload.contact_tel;
        state.current_customer.creation_date = action.payload.creation_date;
        state.current_customer.account.id=action.payload.account?.id || ''
        state.current_customer.account.type=action.payload.account?.type || 0;
        state.current_customer.account.amount=action.payload.account?.amount || 0;
        state.current_customer.account.currency=action.payload.account?.currency || 'MXN';
        state.current_customer.account.linked_cellphone=action.payload.account?.linked_cellphone || '';
        state.current_customer.account.creation_date=action.payload.account?.creation_date || '';
        state.current_customer.account.customer_id=action.payload.account?.customer_id || '';
        state.current_customer.account.clabes=action.payload.account?.clabes || []
      }
    },
    updateCurrentCustomerBalance: (state, action: PayloadAction<number>) => {
      state.current_customer.account.amount = action.payload
    }
  },
});

export const { setProfile, setPartnerInfo, setCustomerInfo, cleanSession, setCurrentCustomer, updateCurrentCustomerBalance } =
  profileSlice.actions;
export const user = (state: RootState) => state.user;
export default profileSlice.reducer;
