import React from 'react';
const link = ({fill, stroke, className}) => {
    return (<><svg className={className} width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.9854 26.25H25.2504C29.7804 26.25 33.5004 22.545 33.5004 18C33.5004 13.47 29.7954 9.75 25.2504 9.75H22.9854" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14 9.75H11.75C7.205 9.75 3.5 13.455 3.5 18C3.5 22.53 7.205 26.25 11.75 26.25H14" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.5 18H24.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</>)
}
export default link;
    